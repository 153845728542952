import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactTransliterate, TriggerKeys } from "react-transliterate";

import Header from "../../components/Header";
import { whatsappTemplates } from "../../data/templates";
import { selectToggle } from "../../redux/slices/NavigationSlice";
import api from "../../utils/axios";

const WhatsappMessage = () => {
  const { t } = useTranslation();
  const lang = useSelector(selectToggle);

  const [selectedTemplate, setselectedTemplate] = useState(0);
  const [to, setTo] = useState("");
  const [isAll, setIsAll] = useState(false);
  const [message, setMessage] = useState(whatsappTemplates[0].text);
  const [loading, setLoading] = useState(false);
  const [numberValidation, setNumberValidation] = useState();

  const changeTemplate = (id) => {
    setselectedTemplate(id);
    setMessage(whatsappTemplates[id].text);
  };

  const handleSubmit = () => {
    setNumberValidation();
    setLoading(true);
    const request = {
      phone: to,
      message: message,
    };

    if (!isAll && !to) {
      setNumberValidation("common.required");
      setLoading(false);
      return;
    }

    if (isAll) {
      api
        .post(`whatsapp/sendMessages`, request)
        .then((response) => {
          if (response.status === 200) {
            alert("message sent successfully");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      api
        .post(`whatsapp/sendMessage`, request)
        .then((response) => {
          if (response.status === 200) {
            alert("message sent successfully");
          }
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  return (
    <div>
      {/* nav */}
      <Header />

      <section className="container-fluid">
        <div className="data-block">
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                {/* lane-1 */}
                <div className="row gx-4 mt-3">
                  {/* to */}
                  <div className="row">
                    <div className="col-md-8">
                      <label>{t("common.name")}</label>
                      <input
                        value={to}
                        type="number"
                        id="to"
                        disabled={isAll}
                        className="form-control"
                        onChange={(e) => setTo(e.target.value)}
                        placeholder="નંબર નાખો"
                      />
                      {numberValidation ? (
                        <p className="form-validation">{t(numberValidation)}</p>
                      ) : null}
                    </div>
                    {/* all -checkbox */}
                    <div className="col-md-3 mt-4">
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          defaultValue={isAll}
                          onChange={(e) => setIsAll(!isAll)}
                          id="all"
                        />
                        <label className="form-check-label" htmlFor="all">
                          {t("common.all")}
                        </label>
                      </div>
                    </div>
                  </div>

                  {/* content */}
                  <div className="col-md-8 mt-3">
                    <label>{t("common.message")}</label>
                    <ReactTransliterate
                      renderComponent={(props) => (
                        <textarea cols={5} rows={15} {...props} />
                      )}
                      value={message}
                      onChangeText={(text) => {
                        setMessage(text);
                      }}
                      className="form-control"
                      lang={lang}
                      enabled={lang === "gu"}
                      triggerKeys={[
                        TriggerKeys.KEY_RETURN,
                        TriggerKeys.KEY_ENTER,
                        TriggerKeys.KEY_SPACE,
                        TriggerKeys.KEY_TAB,
                      ]}
                      placeholder="અહીંયા ટાઈપ કરો...."
                    />
                  </div>
                </div>

                {/* lane-3 */}
                <div className="row gx-4 mt-3">
                  {/* send button */}
                  <div className="col-md-4">
                    <button
                      type="submit"
                      className="button-primary"
                      onClick={handleSubmit}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="m-2">{t("common.loading")}</span>
                        </>
                      ) : (
                        <span className="m-2"> {t("common.sendMessage")}</span>
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row row-cols-1 row-cols-lg-5 g-2 g-lg-3 mt-4">
            {whatsappTemplates.map((template, index) => {
              return (
                <div
                  className="col"
                  key={index}
                  disabled={loading}
                  onClick={() => changeTemplate(index)}
                >
                  <div
                    className={
                      index === selectedTemplate
                        ? "p-3 border bg-light border border-2 border-secondary"
                        : "p-3 border bg-light"
                    }
                  >
                    {template.text}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
};

export default WhatsappMessage;
