import React from "react";
import { Link } from "react-router-dom";
import "./404.css";

const NotFound = () => {
  return (
    <div className="not-found d-flex flex-column">
      <h1 className="not-found-header">404</h1>
      <p className="not-found-text">Oops! Page not found.</p>
      <Link
        to="/"
        className="btn btn-link text-decoration-none text-capitalize"
      >
        Go to homepage
      </Link>
    </div>
  );
};

export default NotFound;
