import { date } from "../../../utils/helper";
import {
  pantImageOptions,
  shirtImageOptions,
} from "../../../data/image-options";
import {
  pantType as pants,
  pocketType as pockets,
  shirtType as shirts,
} from "../../../data/types";

export const initialValues = {
  // common
  date: date,
  deliveryDate: date,
  advance: "",
  amount: 0,
  clothAdvance: "",
  customer: {
    name: "",
    address: "",
    mobile: "",
    // pant
    pant: {
      pantType: pants[0].key,
      pocketType: pockets[0].key,
      image: pantImageOptions[0].key,
      pantCount: "",
      pantDetails: "",
      p1: "",
      p2: "",
      p3: "",
      p4: "",
      p5: "",
      p6: "",
      p7: "",
      p8: "",
      p9: "",
      p10: "",
    }, // shirt
    shirt: {
      shirtType: shirts[0].key,
      image: shirtImageOptions[0].key,
      shirtCount: "",
      shirtDetails: "",
      s1: "",
      s2: "",
      s3: "",
      s4: "",
      s5: "",
      s6: "",
      s7: "",
      s8: "",
      s9: "",
      s10: "",
      s11: "",
      s12: "",
      s13: "",
    },
  },
};

export const validate = (values) => {
  const errors = {};
  if (!values.customer.name) {
    errors.name = "common.required";
  }

  if (!values.customer.address) {
    errors.address = "common.required";
  }

  // if (!values.customer.mobile) {
  //   errors.mobile = "common.required";
  // } else if (!new RegExp("^[0-9].{9}$").test(values.customer.mobile)) {
  //   errors.mobile = "common.phoneValidation";
  // }

  // if (!values.deliveryDate) {
  //   errors.deliveryDate = "common.required";
  // }

  // if (!values.amount) {
  //   errors.amount = "common.required";
  // }

  return errors;
};

export const setOrderValues = (
  formik,
  order,
  setPantOptions,
  setShirtOptions,
) => {
  const { data, type } = order;
  const { customer } = data;
  const { pant, shirt } = customer;

  if (type === "edit") {
    formik.setFieldValue("billNo", data.billNo);
  }

  if (type === "save") {
    formik.values.billNo && delete formik.values.billNo;
  }

  formik.setFieldValue("customer.name", customer.name);
  formik.setFieldValue(
    "customer.address",
    customer.address ? customer.address : "",
  );
  formik.setFieldValue("customer.mobile", customer.mobile);

  if (type === "edit") {
    formik.setFieldValue("date", new Date(data.date));
  }
  // formik.setFieldValue("date", new Date(data.date));
  formik.setFieldValue("advance", data.advance);
  formik.setFieldValue("amount", data.amount ? data.amount : "");
  formik.setFieldValue("clothAdvance", data.clothAdvance);
  formik.setFieldValue("deliveryDate", new Date(data.deliveryDate));

  // only if shirt is not null
  if (pant) {
    formik.setFieldValue("customer.pant.pocketType", pant.pocketType);
    formik.setFieldValue("customer.pant.pantType", pant.pantType);
    formik.setFieldValue("customer.pant.image", pant.image);
    formik.setFieldValue("customer.pant.p1", pant.p1);
    formik.setFieldValue("customer.pant.p2", pant.p2);
    formik.setFieldValue("customer.pant.p3", pant.p3);
    formik.setFieldValue("customer.pant.p4", pant.p4);
    formik.setFieldValue("customer.pant.p5", pant.p5);
    formik.setFieldValue("customer.pant.p6", pant.p6);
    formik.setFieldValue("customer.pant.p7", pant.p7);
    formik.setFieldValue("customer.pant.p8", pant.p8);
    formik.setFieldValue("customer.pant.p9", pant.p9);
    formik.setFieldValue("customer.pant.p10", pant.p10);
    formik.setFieldValue("customer.pant.pantCount", pant.pantCount);
    formik.setFieldValue("customer.pant.pantDetails", pant.pantDetails);

    // pant options
    let pantOptionsArr = [];
    if (pant.pantOptions && pant.pantOptions.length > 0) {
      pantOptionsArr = pant.pantOptions.split(",");
    }
    setPantOptions(pantOptionsArr);
  }

  // only if shirt is not null
  if (customer.shirt) {
    formik.setFieldValue("customer.shirt.shirtType", shirt.shirtType);
    formik.setFieldValue("customer.shirt.image", shirt.image);
    formik.setFieldValue("customer.shirt.s1", shirt.s1);
    formik.setFieldValue("customer.shirt.s2", shirt.s2);
    formik.setFieldValue("customer.shirt.s3", shirt.s3);
    formik.setFieldValue("customer.shirt.s4", shirt.s4);
    formik.setFieldValue("customer.shirt.s5", shirt.s5);
    formik.setFieldValue("customer.shirt.s6", shirt.s6);
    formik.setFieldValue("customer.shirt.s7", shirt.s7);
    formik.setFieldValue("customer.shirt.s8", shirt.s8);
    formik.setFieldValue("customer.shirt.s9", shirt.s9);
    formik.setFieldValue("customer.shirt.s10", shirt.s10);
    formik.setFieldValue("customer.shirt.s11", shirt.s11);
    formik.setFieldValue("customer.shirt.s12", shirt.s12);
    formik.setFieldValue("customer.shirt.s13", shirt.s13);
    formik.setFieldValue("customer.shirt.shirtCount", shirt.shirtCount);
    formik.setFieldValue("customer.shirt.shirtDetails", shirt.shirtDetails);

    // shirt options
    let shirtOptionsArr = [];
    if (shirt.shirtOptions && shirt.shirtOptions.length > 0) {
      shirtOptionsArr = shirt.shirtOptions.split(",");
    }
    setShirtOptions(shirtOptionsArr);
  }
};
