import { combineReducers } from "redux";
import customerSlice from "./slices/CustomerSlice";
import navigationSlice from "./slices/NavigationSlice";
import workerSlice from "./slices/WorkerSlice";
// import userSlice from "./slices/UserSlice";

const rootReducer = combineReducers({
  navigation: navigationSlice,
  worker: workerSlice,
  customer: customerSlice,
  // user: userSlice,
});

export default rootReducer;
