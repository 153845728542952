import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";

// import { composeWithDevTools } from "redux-devtools-extension";
import rootReducer from "./reducers";

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    process.env.NODE_ENV !== "production"
      ? getDefaultMiddleware().concat(logger)
      : getDefaultMiddleware(),
  devTools: process.env.NODE_ENV !== "production",
});
export default store;
