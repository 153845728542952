import React from "react";
import store from "./redux/store";

import { createRoot } from "react-dom/client";
import { RouterProvider } from "react-router-dom";
import { Provider } from "react-redux";
import { AuthProvider } from "./utils/auth";
import { router } from "./data/routes";

// css files
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.js";
import "react-datepicker/dist/react-datepicker.css";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import "./styles/global.css";
import "./styles/print.css";
import "./styles/fonts.css";

// config files
import "./i18n";
import "react-transliterate/dist/index.css";

createRoot(document.getElementById("root")).render(
  <Provider store={store}>
    <React.StrictMode>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </React.StrictMode>
  </Provider>,
);
