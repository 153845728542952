import React from "react";

const Footer = () => {
  return (
    <div className="m-0">
      <footer className="text-center text-lg-start bg-transprent text-muted footer  ">
        <div className="text-center p-4">
          <strong className="text-capitalize">
            © {new Date().getFullYear()} Copyright: darji point (Rajubhai
            Makwana)
          </strong>

          <p>
            Designed and Developed by{" "}
            <a href="https://krunal-hingu.vercel.app" target="_blank">
              Krunal Hingu
            </a>
          </p>
          {/* </a> */}
        </div>
      </footer>
    </div>
  );
};

export default Footer;
