import React, { useState } from "react";
import { message } from "antd";
import { ReactTransliterate, TriggerKeys } from "react-transliterate";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { changeTab, setCustomer } from "../../../redux/slices/CustomerSlice";
import { selectToggle } from "../../../redux/slices/NavigationSlice";

import CurrencyFormat from "react-currency-format";
import Measurements from "../../../components/measurement/Measurements";
import PrintBillModal from "../../../components/Bill/PrintBillModal";
import api from "../../../utils/axios";
import DownloadBillModal from "../../../components/Bill/DownloadBillModal";
import moment from "moment";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";

const Search = () => {
  const dispatch = useDispatch();
  const lang = useSelector(selectToggle);
  const { t } = useTranslation();

  // hooks
  const [isNameValid, setIsNameValid] = useState(false);
  const [isMobileValid, setIsMobileValid] = useState(false);
  const [isBillValid, setIsBillValid] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [billNo, setBillNo] = useState("");
  const [tableData, setTableData] = useState([]);
  const [nameLoading, setNameLoading] = useState(false);
  const [billNoLoading, setBillNoLoading] = useState(false);
  const [mobileNoLoading, setMobileNoLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [isPrint, setIsPrint] = useState(false);
  const [isDownload, setIsDownload] = useState(false);
  const [order, setOrder] = useState();

  // handlers
  const isName = (value) => {
    setNameLoading(value);
    setTableLoading(value);
  };

  const isMobile = (value) => {
    setMobileNoLoading(value);
    setTableLoading(value);
  };

  const isBill = (value) => {
    setBillNoLoading(value);
    setTableLoading(value);
  };

  const handleSearchByName = async () => {
    setTableData([]);
    setIsBillValid(false);
    setIsMobileValid(false);
    setIsNameValid(false);
    if (name) {
      isName(true);
      api
        .get(`order/filter/names/${name}`)
        .then(({ data }) => {
          if (data.body.length) {
            setTableData(data.body);
          } else {
            message.info(t("common.noData"));
          }
          isName(false);
          setName("");
        })
        .catch((error) => {
          isName(false);
          console.error(error);
        });
    } else {
      setIsNameValid(true);
    }
  };

  const handleSearchByMobile = async () => {
    setTableData([]);
    setIsBillValid(false);
    setIsMobileValid(false);
    setIsNameValid(false);
    if (mobile) {
      isMobile(true);
      mobile &&
        api
          .get(`order/filter/mobile/${mobile}`)
          .then(({ data }) => {
            if (data.body.length) {
              setTableData(data.body);
            } else {
              message.info(t("common.noData"));
            }
            isMobile(false);
            setMobile("");
          })
          .catch((error) => {
            isMobile(false);
            console.error(error);
          });
    } else {
      setIsMobileValid(true);
    }
  };

  const handleSearchByBill = async () => {
    setTableData([]);
    setIsBillValid(false);
    setIsMobileValid(false);
    setIsNameValid(false);
    if (billNo) {
      isBill(true);
      billNo &&
        api
          .get(`order/filter/bill/${billNo}`)
          .then(({ data }) => {
            if (data.body.length) {
              setTableData(data.body);
            } else {
              message.info(t("common.noData"));
            }
            isBill(false);
            setBillNo("");
          })
          .catch((error) => {
            isBill(false);
            console.error(error);
          });
    } else {
      setIsBillValid(true);
    }
  };

  const handleEdit = (data, type) => {
    const order = {
      data,
      type,
    };

    setTimeout(() => {
      dispatch(setCustomer({ order }));
    }, 500);

    setTimeout(() => {
      dispatch(changeTab({ key: "add" }));
    }, 500);
  };

  const handleGenerateBill = (o) => {
    setIsPrint(true);
    setOrder(o);
  };

  const handleDownloadBill = (o) => {
    setIsDownload(true);
    setOrder(o);
  };

  const handleViewMeasurement = (o) => {
    setOrder(o);
  };

  return (
    <section className="container-fluid">
      <div className="data-block">
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          {/* search by name */}
          <div className="d-flex" style={{ gap: 10 }}>
            <div className="col-auto">
              <label>{t("common.name")} : </label>
            </div>

            {/* name */}
            <div className="col-md-4">
              <ReactTransliterate
                value={name}
                onChangeText={(text) => {
                  setName(text);
                }}
                className="form-control"
                onFocus={(e) => e.target.select()}
                enabled={lang === "gu"}
                lang={lang}
                triggerKeys={[
                  TriggerKeys.KEY_RETURN,
                  TriggerKeys.KEY_ENTER,
                  TriggerKeys.KEY_SPACE,
                  TriggerKeys.KEY_TAB,
                ]}
              />
              {isNameValid ? (
                <p className="form-validation">{t("common.required")}</p>
              ) : null}
            </div>
            <div className="col-md-2">
              <button
                className="btn btn-primary"
                onClick={handleSearchByName}
                disabled={nameLoading}
              >
                {nameLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="m-2">{t("common.loading")}</span>
                  </>
                ) : (
                  <span className="m-2"> {t("common.find")}</span>
                )}
              </button>
            </div>
          </div>

          {/* search by mobile */}
          <div className="d-flex" style={{ gap: 10 }}>
            <div className="col-auto">
              <label>{t("common.mobile")} : </label>
            </div>

            {/* mobile */}
            <div className="col-md-4">
              <input
                type="number"
                value={mobile}
                onFocus={(e) => e.target.select()}
                onChange={(e) => {
                  setMobile(e.target.value);
                }}
                className="form-control"
              />
              {isMobileValid ? (
                <p className="form-validation">{t("common.required")}</p>
              ) : null}
            </div>

            <div>
              <button
                className="btn btn-primary"
                onClick={handleSearchByMobile}
              >
                {mobileNoLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="m-2">{t("common.loading")}</span>
                  </>
                ) : (
                  <span className="m-2"> {t("common.find")}</span>
                )}
              </button>
            </div>
          </div>

          {/* search by bill no */}
          <div className="d-flex" style={{ gap: 10 }}>
            <div className="col-auto">
              <label>{t("common.billNo")} : </label>
            </div>

            {/* bill no */}
            <div className="col-md-4">
              <input
                value={billNo}
                onChange={(e) => setBillNo(e.target.value)}
                onFocus={(e) => e.target.select()}
                className="form-control"
              />
              {isBillValid ? (
                <p className="form-validation">{t("common.required")}</p>
              ) : null}
            </div>

            <div>
              <button className="btn btn-primary" onClick={handleSearchByBill}>
                {billNoLoading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="m-2">{t("common.loading")}</span>
                  </>
                ) : (
                  <span className="m-2"> {t("common.find")}</span>
                )}
              </button>
            </div>
          </div>
        </div>

        <hr />
        {/* table */}
        {tableLoading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="m-2">{t("common.loading")}</span>
          </div>
        ) : (
          tableData &&
          tableData.length != 0 && (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{t("common.billNo")}</th>
                    <th scope="col">{t("common.date")}</th>
                    <th scope="col">{t("common.name")}</th>
                    <th scope="col">{t("common.mobile")}</th>
                    <th scope="col">{t("common.pant")}</th>
                    <th scope="col">{t("common.shirt")}</th>
                    <th scope="col">{t("common.advance")}</th>
                    <th scope="col">{t("common.total")}</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((order, index) => (
                    <tr key={order.billNo}>
                      <th scope="row">{order.billNo}</th>
                      <td>
                        {order.date && moment(order.date).format("DD/MM/yyyy")}
                      </td>
                      <td>{order.customer.name}</td>
                      <td>{order.customer.mobile}</td>
                      <td>
                        {order.customer.pant && order.customer.pant.pantCount
                          ? order.customer.pant.pantCount
                          : 0}
                      </td>
                      <td>
                        {order.customer.shirt && order.customer.shirt.shirtCount
                          ? order.customer.shirt.shirtCount
                          : 0}
                      </td>
                      <td>{order.advance ? order.advance : 0}</td>
                      <td>{order.amount ? order.amount : 0}</td>
                      <td>
                        <div className="d-flex">
                          <button
                            onClick={() => handleEdit(order, "save")}
                            className="btn"
                          >
                            <img
                              src="./images/save.png"
                              alt="edit"
                              width={20}
                            />{" "}
                            {t("common.save")}
                          </button>

                          <button
                            className="btn"
                            onClick={() => handleGenerateBill(order)}
                          >
                            <img
                              src="./images/invoice.png"
                              alt="bill"
                              width={20}
                            />{" "}
                            {t("common.generateBill")}
                          </button>

                          <button
                            className="btn"
                            onClick={() => handleDownloadBill(order)}
                          >
                            <img
                              src="./images/download.png"
                              alt="bill"
                              width={20}
                            />{" "}
                            {t("common.download")}
                          </button>

                          <button
                            type="button"
                            className="btn"
                            data-bs-toggle="modal"
                            data-bs-target="#measurementModal"
                            onClick={() => handleViewMeasurement(order)}
                          >
                            <img
                              src="./images/show.png"
                              alt="bill"
                              width={20}
                            />{" "}
                            {t("common.viewMeasure")}
                          </button>

                          <button
                            onClick={() => handleEdit(order, "edit")}
                            className="btn"
                          >
                            <img
                              src="./images/edit.png"
                              alt="edit"
                              width={20}
                            />{" "}
                            {t("common.update")}
                          </button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}
      </div>

      <PrintBillModal order={order} isOpen={isPrint} setIsOpen={setIsPrint} />
      <DownloadBillModal
        order={order}
        isOpen={isDownload}
        setIsOpen={setIsDownload}
      />
      <Measurements order={order} />
    </section>
  );
};

export default Search;
