export const pantImageOptions = [
  {
    key: "frame1",
    src: "./images/pant-images/frame1.png",
    alt: "frame1",
  },
  {
    key: "frame2",
    src: "./images/pant-images/frame2.png",
    alt: "frame2",
  },
  {
    key: "frame3",
    src: "./images/pant-images/frame3.png",
    alt: "frame3",
  },
  {
    key: "frame4",
    src: "./images/pant-images/frame4.png",
    alt: "frame4",
  },
  {
    key: "frame5",
    src: "./images/pant-images/frame5.png",
    alt: "frame5",
  },
  {
    key: "frame6",
    src: "./images/pant-images/frame6.png",
    alt: "frame6",
  },
  {
    key: "frame7",
    src: "./images/pant-images/frame7.png",
    alt: "frame7",
  },
  {
    key: "frame8",
    src: "./images/pant-images/frame8.png",
    alt: "frame8",
  },
  {
    key: "frame9",
    src: "./images/pant-images/frame9.png",
    alt: "frame9",
  },
  {
    key: "frame10",
    src: "./images/pant-images/frame10.png",
    alt: "frame10",
  },
  {
    key: "frame11",
    src: "./images/pant-images/frame11.png",
    alt: "frame11",
  },
  {
    key: "frame12",
    src: "./images/pant-images/frame12.png",
    alt: "frame12",
  },
  {
    key: "frame13",
    src: "./images/pant-images/frame13.png",
    alt: "frame13",
  },
  {
    key: "frame14",
    src: "./images/pant-images/frame14.png",
    alt: "frame14",
  },
  {
    key: "frame15",
    src: "./images/pant-images/frame15.png",
    alt: "frame15",
  },
  {
    key: "frame16",
    src: "./images/pant-images/frame16.png",
    alt: "frame16",
  },
  {
    key: "frame17",
    src: "./images/pant-images/frame17.png",
    alt: "frame17",
  },
];

export const shirtImageOptions = [
  {
    key: "frame1",
    src: "./images/shirt-images/frame1.png",
    alt: "frame1",
  },
  {
    key: "frame2",
    src: "./images/shirt-images/frame2.png",
    alt: "frame2",
  },
  {
    key: "frame3",
    src: "./images/shirt-images/frame3.png",
    alt: "frame3",
  },
];
