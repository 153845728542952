// pant
export const pantOptions = [
  { name: "pant.options.grip", key: "grip" },
  { name: "pant.options.fittingShape", key: "fittingShape" },
  { name: "pant.options.singleSilai", key: "singleSilai" },
  { name: "pant.options.doubleSilai", key: "doubleSilai" },
  { name: "pant.options.hathSilai", key: "hathSilai" },
  { name: "pant.options.moliSilai", key: "moliSilai" },
  { name: "pant.options.gajButtonChorasBelt", key: "gajButtonChorasBelt" },
];

// shirt
export const shirtOptionOne = [
  { name: "shirt.options.uparPocket", key: "uparPocket" },
  { name: "shirt.options.andarBaharPocket", key: "andarBaharPocket" },
  { name: "shirt.options.sadaPocket", key: "sadaPocket" },
  { name: "shirt.options.cutPocket", key: "cutPocket" },
  { name: "shirt.options.uparPatti", key: "uparPatti" },
  { name: "shirt.options.chineseColar", key: "chineseColar" },
  { name: "shirt.options.colorOneInch", key: "colorOneInch" },
  { name: "shirt.options.fancyButton", key: "fancyButton" },
  { name: "shirt.options.ringButton", key: "ringButton" },
];

export const shirtOptionTwo = [
  { name: "shirt.options.noPocket", key: "noPocket" },
  { name: "shirt.options.logoTanki", key: "logoTanki" },
  { name: "shirt.options.pachadDarts", key: "pachadDarts" },
  { name: "shirt.options.pocket2", key: "pocket2" },
  { name: "shirt.options.pocket1", key: "pocket1" },
  { name: "shirt.options.gajButton", key: "gajButton" },
  { name: "shirt.options.foldPatti", key: "foldPatti" },
  { name: "shirt.options.halfBuy", key: "halfBuy" },
];
