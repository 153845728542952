export const links = [
  {
    name: "nav.customer",
    key: "customer",
    path: "/",
  },
  {
    name: "nav.worker",
    key: "worker",
    path: "/worker",
  },
  {
    name: "nav.expense",
    key: "expense",
    path: "/expense",
  },
  {
    name: "nav.whatsapp",
    key: "whatsapp",
    path: "/message",
  },
];
