import React from "react";
import { useTranslation } from "react-i18next";

const Measurements = ({ order }) => {
  const { t } = useTranslation();
  return (
    <div
      className="modal fade"
      id="measurementModal"
      tabIndex="-1"
      aria-labelledby="measurementModalLabel"
      aria-hidden="true"
    >
      <div className="modal-lg modal-dialog-scrollable">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                {order && `${order.billNo} - ${order.customer.name}`}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              <div className="container-fluid">
                {/* pant */}
                {order && order.customer.pant && (
                  <div className="col-md-12 border border-1 p-3 mb-4">
                    <strong>
                      <h5>{t("common.pant")}</h5>
                    </strong>
                    <div className="row mt-2 ">
                      <div className="col text-center">
                        <div className="row g-2">
                          {/* layer-1 */}
                          <div className="row g-2">
                            <div className="col text-center p-2 fs-5">
                              {order.customer.pant.p1}
                            </div>
                            <div className="col text-center p-2 fs-5">
                              {order.customer.pant.p2}
                            </div>
                            <div className="col text-center p-2 fs-5">
                              {order.customer.pant.p3}
                            </div>
                            <div className="col text-center p-2 fs-5">
                              {order.customer.pant.p4}
                            </div>
                            <div className="col text-center p-2 fs-5">
                              {order.customer.pant.p5}
                            </div>
                            <div className="col text-center p-2 fs-5">
                              {order.customer.pant.p6}
                            </div>
                          </div>
                          {/* layer-2 */}
                          <div className="row g-2">
                            <div className="col text-center p-2 fs-5">
                              {order.customer.pant.p7}
                            </div>
                            <div className="col"></div>
                            <div className="col text-center p-2 fs-5">
                              {order.customer.pant.p8}
                            </div>
                            <div className="col text-center p-2 fs-5">
                              {order.customer.pant.p9}
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>
                          {/* layer-3 */}
                          <div className="row g-2">
                            <div className="col text-center p-2 fs-5">
                              {order.customer.pant.p10}
                            </div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                            <div className="col"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* shirt */}
                {order && order.customer.shirt && (
                  <div className="col-md-12 border border-1 p-3 mb-4">
                    <strong>
                      <h5>{t("common.shirt")}</h5>
                    </strong>
                    <div className="row g-2 mt-2">
                      {/* layer-1 */}
                      <div className="row g-2">
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s1}
                        </div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s2}
                        </div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s3}
                        </div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s4}
                        </div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s5}
                        </div>
                        <div className="col"></div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s6}
                        </div>
                      </div>
                      {/* layer-2 */}
                      <div className="row g-2">
                        <div className="col"></div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s7}
                        </div>
                        <div className="col"></div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s8}
                        </div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s9}
                        </div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s10}
                        </div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s11}
                        </div>
                      </div>
                      {/* layer-3 */}
                      <div className="row g-2">
                        <div className="col"></div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s12}
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                      {/* layer-4 */}
                      <div className="row g-2">
                        <div className="col"></div>
                        <div className="col text-center p-2 fs-5">
                          {order.customer.shirt.s13}
                        </div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                        <div className="col"></div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Measurements;
