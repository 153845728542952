import { createSlice } from "@reduxjs/toolkit";

export const navigationSlice = createSlice({
  name: "navigation",
  initialState: {
    languageToggle: localStorage.getItem("i18nextLng"),
  },
  reducers: {
    setLanguage: (state, action) => {
      state.languageToggle = action.payload.toggle;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setHeader, setLanguage } = navigationSlice.actions;

export const selectToggle = (state) => state.navigation.languageToggle;

export default navigationSlice.reducer;
