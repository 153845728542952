import React from "react";

const Pagination = ({ count, currentPage = 1, handleChange }) => {
  return (
    <nav aria-label="Page navigation">
      <ul className="pagination justify-content-end">
        <li className={currentPage === 0 ? "page-item disabled" : "page-item"}>
          <button
            className="page-link"
            onClick={() => handleChange(currentPage - 1)}
            aria-label="Previous"
          >
            <span aria-hidden="true">&laquo;</span>
          </button>
        </li>

        {[...Array(count)].map((n, i) =>
          currentPage === i ? (
            <li className="page-item" key={i}>
              <button
                className="page-link active"
                onClick={() => handleChange(i)}
                aria-label="Next"
              >
                <span aria-hidden="true">{i + 1}</span>
              </button>
            </li>
          ) : (
            <li className="page-item " key={i}>
              <button
                className="page-link"
                href="#"
                aria-label="Next"
                onClick={() => handleChange(i)}
              >
                <span aria-hidden="true">{i + 1}</span>
              </button>
            </li>
          ),
        )}

        <li
          className={
            currentPage === count - 1 ? "page-item disabled" : "page-item"
          }
        >
          <button
            className="page-link"
            aria-label="Next"
            onClick={() => handleChange(currentPage + 1)}
          >
            <span aria-hidden="true">&raquo;</span>
          </button>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
