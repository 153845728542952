import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { ReactTransliterate, TriggerKeys } from "react-transliterate";
import { initialValues, setOrderValues, validate } from "./initialValues";
import { selectToggle } from "../../../redux/slices/NavigationSlice";
import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  removeCustomer,
  selectCustomer,
} from "../../../redux/slices/CustomerSlice";
import { date, localDate } from "../../../utils/helper";

// components
import DatePicker from "react-date-picker";
// import DatePicker from "react-datepicker";
import Pant from "../../../components/Pant";
import Shirt from "../../../components/Shirt";
import api from "../../../utils/axios";
import BillModal from "../../../components/Bill/PrintBillModal";

const AddDetails = () => {
  const dispatch = useDispatch();
  const customer = useSelector(selectCustomer);
  const lang = useSelector(selectToggle);
  const { t } = useTranslation();

  // hooks
  const [billNo, setBillNo] = useState();
  const [isPant, setIsPant] = useState(true);
  const [pantOptions, setPantOptions] = useState([]);
  const [shirtOptions, setShirtOptions] = useState([]);

  const [isShirt, setIsShirt] = useState(true);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [isWhatsapp, setIsWhatsapp] = useState(false);
  const [order, setOrder] = useState();

  // for bill no
  useEffect(() => {
    getBillNo();
  }, []);

  // for edit
  useEffect(() => {
    return () => {
      const { order } = customer;
      const { data, type } = order;

      // changing bill no to upadate the record
      if (type && type === "edit") {
        setBillNo(data.billNo);
      } else if (type && type === "save") {
        getBillNo();
      }

      if (Object.keys(order).length !== 0) {
        setIsPant(true);
        setIsShirt(true);
        setOrderValues(formik, order, setPantOptions, setShirtOptions);
      }
    };
  }, [customer]);

  // fetches latest bill no
  const getBillNo = async () => {
    await api.get("order/latest").then((response) => {
      if (response.status === 200) {
        setBillNo(response.data.body.id);
      }
    });
  };

  // options
  const handlePantOptionChange = (value) => {
    setPantOptions((checkedBoxes) => {
      if (checkedBoxes.includes(value)) {
        return checkedBoxes.filter((box) => box !== value);
      } else {
        return [...checkedBoxes, value];
      }
    });
  };

  const handleShirtOptionChange = (value) => {
    setShirtOptions((checkedBoxes) => {
      if (checkedBoxes.includes(value)) {
        return checkedBoxes.filter((box) => box !== value);
      } else {
        return [...checkedBoxes, value];
      }
    });
  };

  // convert options to comma separated string
  function optionsToString(options) {
    let optionsToString = "";
    if (options) {
      Object.entries(options).forEach(([key, value]) => {
        if (value) {
          optionsToString = optionsToString.concat(`${key},`);
        }
      });

      // remove last index
      optionsToString = optionsToString.slice(0, -1);
    }
    return optionsToString;
  }

  const formik = useFormik({
    initialValues: initialValues,
    validate,
    onSubmit: (request, { resetForm }) => {
      setLoading(true);

      // modify & add options to request
      request.customer.pant.pantOptions = pantOptions.join(",");
      request.customer.shirt.shirtOptions = shirtOptions.join(",");

      if (isPant || isShirt) {
        // removing unwanted objects from request
        if (!isPant) delete request.customer["pant"];

        if (!isShirt) delete request.customer["shirt"];

        const payload = {
          whatsapp: isWhatsapp,
          order: request,
        };

        // add order details (place order)
        api
          .post("order", payload)
          .then((response) => {
            if (response.status === 200) {
              setOrder(response.data.body);
              setIsOpen(true);

              message.success(
                "Order Placed Succesfully for " + response.data.body.billNo,
              );

              resetForm();
              // updating bill number
              getBillNo();
            } else {
              message.error("Error occured : " + response.data.errorMessage);
            }

            setLoading(false);
            setPantOptions([]);
            setShirtOptions([]);
          })
          .catch((error) => {
            message.error(t("common.errorMessage"));
            setLoading(false);
            console.error(error);
          })
          .finally(dispatch(removeCustomer()));
      } else {
        setLoading(false);
        message.warning("Choose Atlest One Option : Pant or Shirt");
      }
    },
  });

  return (
    <section className="container-fluid">
      {/* form*/}
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="data-block">
          {/* bill no */}
          <div className="text-end">
            <label>
              {t("common.billNo")} : {billNo}
            </label>
          </div>
          <div className="d-flex">
            {/* block -1 */}
            <div className="d-flex flex-column col-md-4">
              {/* name */}
              <div className="col-md-12 input-block">
                <label>{t("common.name")}</label>
                <ReactTransliterate
                  value={formik.values.customer.name}
                  onChangeText={(text) => {
                    formik.setFieldValue("customer.name", text);
                  }}
                  className="form-control"
                  onFocus={(e) => e.target.select()}
                  lang={lang}
                  enabled={lang === "gu"}
                  triggerKeys={[
                    TriggerKeys.KEY_RETURN,
                    TriggerKeys.KEY_ENTER,
                    TriggerKeys.KEY_SPACE,
                    TriggerKeys.KEY_TAB,
                  ]}
                />
                {formik.errors.name &&
                formik.touched.customer &&
                formik.touched.customer.name ? (
                  <p className="form-validation">{t(formik.errors.name)}</p>
                ) : null}
              </div>
              <div className="col-md-12 input-block">
                {/* address */}
                <label>{t("common.address")}</label>
                <ReactTransliterate
                  renderComponent={(props) => (
                    <textarea cols={5} rows={3} {...props} />
                  )}
                  value={formik.values.customer.address}
                  onFocus={(e) => e.target.select()}
                  onChangeText={(text) => {
                    formik.setFieldValue("customer.address", text);
                  }}
                  className="form-control"
                  lang={lang}
                  enabled={lang === "gu"}
                  triggerKeys={[
                    TriggerKeys.KEY_RETURN,
                    TriggerKeys.KEY_ENTER,
                    TriggerKeys.KEY_SPACE,
                    TriggerKeys.KEY_TAB,
                  ]}
                />
                {formik.errors.address &&
                formik.touched.customer &&
                formik.touched.customer.address ? (
                  <p className="form-validation">{t(formik.errors.address)}</p>
                ) : null}
              </div>
            </div>

            {/* block-2 */}
            <div className="d-flex flex-column offset-md-4 col-md-4">
              {/* date */}
              <div className="col-md-12 input-block">
                <label>{t("common.date")}</label>
                <DatePicker
                  className="form-control"
                  // disabled
                  format="dd/MM/yyyy"
                  clearIcon={null}
                  disableCalendar
                  value={formik.values.date}
                  onChange={(date) =>
                    formik.setFieldValue("date", localDate(date))
                  }
                />
              </div>
              {/* mobile */}
              <div className="col-md-12 input-block">
                <label>{t("common.mobile")}</label>
                <input
                  id="customer.mobile"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  value={formik.values.customer.mobile}
                  onChange={formik.handleChange}
                  className="form-control"
                />
                {formik.errors.mobile &&
                formik.touched.customer &&
                formik.touched.customer.mobile ? (
                  <p className="form-validation">{t(formik.errors.mobile)}</p>
                ) : null}
              </div>
            </div>
          </div>

          <div className="d-flex flex-column">
            <div>
              <div className="d-flex justify-content-start flex-wrap m-2">
                <div>
                  {/* checkbox - isWhatsapp */}
                  <div className="form-check m-2">
                    <label className="form-check-label" htmlFor="whatsapp">
                      <div className="d-flex g-2">
                        <img
                          src="/images/whatsapp.png"
                          alt="pant"
                          width={20}
                          height={20}
                        />
                        <p className="mx-2">{t("common.whatsappMessage")}</p>
                      </div>
                    </label>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      checked={isWhatsapp}
                      value={isWhatsapp}
                      onChange={() => setIsWhatsapp(!isWhatsapp)}
                      id="whatsapp"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start flex-wrap m-2">
                {/* checkbox - isPant */}
                <div className="form-check m-2">
                  <label className="form-check-label" htmlFor="pant">
                    <img src="/images/pants.png" alt="pant" width={100} />
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isPant}
                    value={isPant}
                    onChange={(e) => setIsPant(!isPant)}
                    id="pant"
                  />
                </div>

                {/* checkbox - isShirt */}
                <div className="form-check m-2">
                  <label className="form-check-label" htmlFor="shirt">
                    <img src="/images/shirt.png" alt="shirt" width={100} />
                  </label>
                  <input
                    className="form-check-input"
                    type="checkbox"
                    checked={isShirt}
                    value={isShirt}
                    onChange={(e) => setIsShirt(!isShirt)}
                    id="shirt"
                  />
                </div>
              </div>
            </div>
          </div>

          {/* divider */}
          <hr />

          {/* pant */}
          {isPant ? (
            <Pant
              formik={formik}
              options={pantOptions}
              handlePantOptionChange={handlePantOptionChange}
            />
          ) : null}

          {/* shirt */}
          {isShirt ? (
            <Shirt
              formik={formik}
              options={shirtOptions}
              handleShirtOptionChange={handleShirtOptionChange}
            />
          ) : null}

          {/* bottom */}
          <div className="row g-3">
            {/* delivery date */}
            <div className="col-md-2">
              <label>{t("common.delieveryDate")}</label>
              <DatePicker
                minDate={date}
                className="form-control m-0"
                format="dd/MM/yyyy"
                value={formik.values.deliveryDate}
                clearIcon={null}
                disableCalendar
                onChange={(date) =>
                  formik.setFieldValue("deliveryDate", localDate(date))
                }
              />

              {formik.errors.deliveryDate && formik.touched.deliveryDate ? (
                <p className="form-validation">
                  {t(formik.errors.deliveryDate)}
                </p>
              ) : null}
            </div>
            {/* clothe advance button */}
            <div className="col-md-2">
              <label>{t("common.clothAdvance")}</label>
              <input
                type="number"
                id="clothAdvance"
                onFocus={(e) => e.target.select()}
                value={formik.values.clothAdvance}
                className="form-control"
                onChange={formik.handleChange}
              />
            </div>
            {/* advance button */}
            <div className="col-md-2">
              <label>{t("common.advance")}</label>
              <input
                type="number"
                id="advance"
                onFocus={(e) => e.target.select()}
                value={formik.values.advance}
                className="form-control"
                onChange={formik.handleChange}
              />
            </div>
            {/* amount */}
            <div className="col-md-2">
              <label>{t("common.amount")}</label>
              <input
                type="number"
                onFocus={(e) => e.target.select()}
                id="amount"
                value={formik.values.amount}
                className="form-control"
                onChange={formik.handleChange}
              />
              {formik.errors.amount && formik.touched.amount ? (
                <p className="form-validation">{t(formik.errors.amount)}</p>
              ) : null}
            </div>
            {/* submit button */}
            <div className="col-md-2">
              <button type="submit" className="button-primary">
                {loading ? (
                  <>
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="m-2">{t("common.loading")}</span>
                  </>
                ) : (
                  <span className="m-2"> {t("common.save")}</span>
                )}
              </button>
            </div>
            {/* clear button */}
            <div className="col-md-2">
              <button type="reset" className="button-secondary">
                {t("common.clear")}
              </button>
            </div>
          </div>
        </div>
      </form>
      <BillModal order={order} isOpen={isOpen} setIsOpen={setIsOpen} />
    </section>
  );
};

export default AddDetails;
