export const pantType = [
  { name: "pant.type.pant", key: "pant" },
  { name: "pant.type.halfRubberPant", key: "halfRubberPant" },
  { name: "pant.type.jeans", key: "jeans" },
  { name: "pant.type.cottonJeans", key: "cottonJeans" },
  { name: "pant.type.chudidarFartuRubber", key: "chudidarFartuRubber" },
];

export const shirtType = [
  { name: "shirt.type.open", key: "open" },
  { name: "shirt.type.bushart", key: "bushart" },
  { name: "shirt.type.shortShirt", key: "shortShirt" },
  { name: "shirt.type.openKurto", key: "openKurto" },
  { name: "shirt.type.kurto", key: "kurto" },
  { name: "shirt.type.jabbho", key: "jabbho" },
  { name: "shirt.type.safari", key: "safari" },
  { name: "shirt.type.sherwani", key: "sherwani" },
  { name: "shirt.type.koti", key: "koti" },
  { name: "shirt.type.suit", key: "suit" },
  { name: "shirt.type.blazzer", key: "blazer" },
  { name: "shirt.type.indoWestern", key: "indoWestern" },
  { name: "shirt.type.hineckKurto", key: "hineckKurto" },
];

export const pocketType = [
  { name: "T.1 4 | | * 6", key: "t1-small" },
  { name: "T.2 4 | | * 6", key: "t2-small" },
  { name: "T.1 6 * 8", key: "t1-large" },
  { name: "T.2 6 * 8", key: "t2-large" },
];
