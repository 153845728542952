import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { message } from "antd";
import { selectWorkers } from "../../../redux/slices/WorkerSlice";
import { useSelector } from "react-redux";
import { date, handleDatePickerFocus, localDate } from "../../../utils/helper";

import Actions from "../../../components/Actions";
import api from "../../../utils/axios";
import * as Yup from "yup";
import moment from "moment";
import Pagination from "../../../components/Pagination";

const formValidation = Yup.object().shape({
  amount: Yup.number().required("common.required"),
});

const Debt = () => {
  const { t } = useTranslation();
  const { workers } = useSelector(selectWorkers);
  // hooks
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getAllDebts(currentPage, pageSize);
  }, []);

  const getAllDebts = async (page = 0, size = 100) => {
    setTableLoading(true);
    api
      .get(`workerDebt/limit?page=${page}&size=${size}`)
      .then(({ data }) => {
        setTableData(data.body.content);
        setTotalPages(data.body.totalPages);
        setTableLoading(false);
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAllDebts(pageNumber, pageSize);
  };

  const deleteWorkerDebt = async (id) => {
    api
      .delete(`workerDebt/${id}`)
      .then(({ data }) => {
        getAllDebts(currentPage, pageSize);
        message.info("debt deleted -" + id);
      })
      .catch((error) => console.error(error))
      .finally();
  };

  const addWorkerDebt = async (values) => {
    setLoading(true);
    api
      .post("workerDebt", values)
      .then(({ data }) => {
        getAllDebts();
        message.info("debt added successfully");
        setLoading(false);
      })
      .catch((error) => {
        message.error(t("common.errorMessage"));
        setLoading(false);
        console.error(error);
      });
  };

  const handleUpdate = (id) => {
    let selectedItem = tableData.find((item) => item.id === id);

    formik.setFieldValue("workerId", selectedItem.workerId);
    formik.setFieldValue("amount", selectedItem.amount);
    formik.setFieldValue("id", id);
  };

  const handleDelete = (id) => {
    deleteWorkerDebt(id);
  };

  // form
  const formik = useFormik({
    initialValues: {
      workerId: "",
      date: date,
      amount: "",
    },
    validationSchema: formValidation,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      setLoading(true);
      addWorkerDebt(values);
      resetForm();
    },
  });

  return (
    <section className="container-fluid">
      <div className="data-block">
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                {/* lane-1 */}
                <div className="row gx-4 mt-3">
                  {/* name */}
                  <div className="col-md-6">
                    <select
                      id="workerId"
                      disabled={workers && !workers.length}
                      value={formik.values.workerId}
                      onChange={formik.handleChange}
                    >
                      <option value="" key="">
                        Select Worker
                      </option>
                      {workers &&
                        workers.map((worker, index) => (
                          <option value={worker.id} key={index}>
                            {worker.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {/* date */}
                  <div className="col-md-6">
                    <div>
                      <ReactDatePicker
                        onFocus={handleDatePickerFocus}
                        preventOpenOnFocus={true}
                        selected={formik.values.date}
                        className="form-control m-0"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="date"
                        onChange={(date) =>
                          formik.setFieldValue("date", localDate(date))
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* lane-2 */}
                <div className="row gx-4 mt-3">
                  {/* amount */}
                  <div className="col-md-6">
                    <div>
                      <label>{t("common.amount")}</label>
                      <input
                        value={formik.values.amount}
                        id="amount"
                        type="number"
                        className="form-control"
                        onChange={formik.handleChange}
                      />
                      {formik.errors.amount && formik.touched.amount ? (
                        <p className="form-validation">
                          {t(formik.errors.amount)}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* lane-3 */}
                <div className="row gx-4 mt-3">
                  {/* submit button */}
                  <div className="col-md-6">
                    <button type="submit" className="button-primary">
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="m-2">{t("common.loading")}</span>
                        </>
                      ) : (
                        <span className="m-2"> {t("common.save")}</span>
                      )}
                    </button>
                  </div>
                  {/* clear button */}
                  <div className="col-md-6">
                    <button type="reset" className="button-secondary">
                      {t("common.clear")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr />

        {/* table */}
        {tableLoading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="m-2">{t("common.loading")}</span>
          </div>
        ) : (
          tableData &&
          tableData.length !== 0 && (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{t("common.name")}</th>
                    <th scope="col">{t("common.amount")}</th>
                    <th scope="col">{t("common.date")}</th>
                    <th scope="col">{t("common.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData &&
                    tableData.map((workerDebt, index) => (
                      <tr key={workerDebt.id}>
                        <td>{workerDebt.workerName}</td>
                        <td>{workerDebt.amount}</td>
                        <td>
                          {workerDebt.date &&
                            moment(workerDebt.date).format("DD/MM/yyyy")}
                        </td>
                        <td>
                          <Actions
                            index={workerDebt.id}
                            handleDelete={handleDelete}
                            handleUpdate={handleUpdate}
                          />
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          )
        )}

        {/* pagiantion */}
        {totalPages ? (
          <Pagination
            count={totalPages}
            currentPage={currentPage}
            handleChange={handlePageChange}
          />
        ) : null}
      </div>
    </section>
  );
};

export default Debt;
