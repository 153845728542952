import React from "react";

import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { ReactTransliterate, TriggerKeys } from "react-transliterate";
import { pantOptions } from "../data/options";
import { pantType as pants, pocketType } from "../data/types";
import { selectToggle } from "../redux/slices/NavigationSlice";

import PantImageDropdown from "./ImageDropDown/PantImageDropdown";

import PantMeasurement from "./measurement/PantMeasurement";

const Pant = ({ formik, handlePantOptionChange, options }) => {
  const { t } = useTranslation();
  const lang = useSelector(selectToggle);

  return (
    <section className="data-block">
      <div className="row g-2">
        {/* left */}
        <div className="col-md-6">
          <div className="p-3">
            <div className="row g-2">
              <div className="col-md-3">
                {/* dropdown - type */}
                {/* <label>pant type</label> */}
                <select
                  className="form-select"
                  id="customer.pant.pantType"
                  onChange={formik.handleChange}
                  value={formik.values.customer.pant.pantType}
                >
                  {pants.map((pant, index) => (
                    <option key={pant.key} value={pant.key}>
                      {t(pant.name)}
                    </option>
                  ))}
                </select>
              </div>
              {/* dropdown - watch pocket */}
              <div className="col-md-3">
                {/* <label>pocket type</label> */}
                <select
                  className="form-select"
                  id="customer.pant.pocketType"
                  onChange={formik.handleChange}
                  value={formik.values.customer.pant.pocketType}
                >
                  {pocketType.map((type, index) => (
                    <option key={type.key} value={type.key}>
                      {type.name}
                    </option>
                  ))}
                </select>
              </div>

              {/* measurments */}
              <PantMeasurement type="pant" formik={formik} />
            </div>
          </div>
        </div>

        {/* right */}
        <div className="col-md-6">
          <div className="p-3">
            <div className="row gx-5 gy-3">
              <div className="col">
                <PantImageDropdown formik={formik} />
              </div>
              <div className="col">
                <div className="d-flex flex-column">
                  {pantOptions.map(({ key, name }, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={key}
                        value={key}
                        checked={options.includes(key)}
                        onChange={() => handlePantOptionChange(key)}
                      />
                      <label className="form-check-label" htmlFor={key}>
                        {t(name)}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col">
                <div className="col">
                  <label>{t("common.count")}</label>
                  <input
                    type={"number"}
                    onFocus={(e) => e.target.select()}
                    id="customer.pant.pantCount"
                    value={formik.values.customer.pant.pantCount}
                    onChange={formik.handleChange}
                    className="form-control"
                  />
                </div>

                {/* details */}
                <div className="col my-2">
                  <label>{t("common.details")}</label>
                  <ReactTransliterate
                    value={formik.values.customer.pant.pantDetails}
                    renderComponent={(props) => (
                      <textarea cols={5} rows={3} {...props} />
                    )}
                    onFocus={(e) => e.target.select()}
                    onChangeText={(text) => {
                      formik.setFieldValue("customer.pant.pantDetails", text);
                    }}
                    id="customer.pant.pantDetails"
                    className="form-control"
                    lang={lang}
                    enabled={lang === "gu"}
                    triggerKeys={[
                      TriggerKeys.KEY_RETURN,
                      TriggerKeys.KEY_ENTER,
                      TriggerKeys.KEY_SPACE,
                      TriggerKeys.KEY_TAB,
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </section>
  );
};

export default Pant;
