import React from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/Header";
import AddDetails from "./Add";
import Search from "./Search";
import Report from "./report";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { useDispatch, useSelector } from "react-redux";
import { changeTab, selectCustomer } from "../../redux/slices/CustomerSlice";

// tabs
const tabs = [
  {
    title: "common.add",
    key: "add",
    component: <AddDetails />,
  },
  {
    title: "common.search",
    key: "search",
    component: <Search />,
  },
  {
    title: "common.report",
    key: "report",
    component: <Report />,
  },
];

const Customers = () => {
  const customer = useSelector(selectCustomer);

  const dispatch = useDispatch();
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className="tab-container">
        <Tabs
          id="customer-tabs"
          activeKey={customer.currentTab}
          onSelect={(k) => dispatch(changeTab({ key: k }))}
          className="custom-tab"
        >
          {tabs.map((tab, index) => (
            <Tab eventKey={tab.key} title={t(tab.title)} key={index}>
              {tab.component}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Customers;
