import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/Header";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Add from "./Add";
import LogWork from "./LogWork";
import Report from "./Report";
import Debt from "./Debt";
import api from "../../utils/axios";
import { useDispatch } from "react-redux";
import { setWorkers } from "../../redux/slices/WorkerSlice";

// tabs

const Worker = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [key, setKey] = useState("add");

  useEffect(() => {
    const getAllWorker = () => {
      api.get("worker").then(({ data }) => {
        dispatch(setWorkers({ workers: data.body }));
        return;
      });
    };

    getAllWorker();
  }, []);

  const tabs = [
    {
      title: "common.add",
      key: "add",
      component: <Add />,
    },
    {
      title: "common.logWork",
      key: "logWork",
      component: <LogWork />,
    },
    {
      title: "common.debt",
      key: "debt",
      component: <Debt />,
    },
    {
      title: "common.workReport",
      key: "report",
      component: <Report />,
    },
  ];

  return (
    <div>
      <Header />
      <div className="tab-container">
        <Tabs
          id="customer-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="custom-tab"
        >
          {tabs.map((tab, index) => (
            <Tab eventKey={tab.key} title={t(tab.title)} key={index}>
              {tab.component}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Worker;
