import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Header from "../../components/Header";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import Add from "./Add";
import Report from "./Report";

// tabs
const tabs = [
  {
    title: "common.add",
    key: "add",
    component: <Add />,
  },
  {
    title: "common.report",
    key: "report",
    component: <Report />,
  },
];

const Expense = () => {
  const { t } = useTranslation();
  const [key, setKey] = useState("add");

  return (
    <div>
      <Header />
      <div className="tab-container">
        <Tabs
          id="customer-tabs"
          activeKey={key}
          onSelect={(k) => setKey(k)}
          className="custom-tab"
        >
          {tabs.map((tab, index) => (
            <Tab eventKey={tab.key} title={t(tab.title)} key={index}>
              {tab.component}
            </Tab>
          ))}
        </Tabs>
      </div>
    </div>
  );
};

export default Expense;
