export const whatsappTemplates = [
  {
    text: `પ્રિય ગ્રાહક,

આ સંદેશ તમને જણાવવા માટે છે કે તમારો ઓર્ડર *{{1}}*  હવે પિક-અપ માટે તૈયાર છે. અમે વિનંતી કરીએ છીએ કે તમે શક્ય તેટલી વહેલી તકે તમારો ઓર્ડર એકત્રિત કરો. મહેરબાની કરીને નોંધ કરો કે આ સૂચનાની તારીખથી ૩૦ દિવસ પછી અસંગ્રહિત કરાયેલા કોઈપણ ઓર્ડર માટે અમે જવાબદાર હોઈશું નહીં.

જ્યારે તમે તમારો ઓર્ડર લેવા આવો ત્યારે કૃપા કરીને માન્ય ઇન્વૉઇસ/બિલ લાવવાની ખાતરી કરો. અમારા સ્ટોરનો સમય સવારે ૧૦ થી સાંજના ૯ છે અને અમારું સરનામું અમારી પ્રોફાઇલમાં પહેલેથી જ આપવામાં આવ્યું છે. જો તમને કોઈ પ્રશ્નો અથવા ચિંતાઓ હોય, તો કૃપા કરીને અમારો સંપર્ક કરવામાં અચકાશો નહીં.

તમારી ખરીદી બદલ આભાર, અને અમે તમને ટૂંક સમયમાં જોવા માટે આતુર છીએ.

શ્રેષ્ઠ શુભેચ્છા,
_દરજી પોઈન્ટ_`,
  },
  {
    text: `{{1}},

દરજી પોઇન્ટ પસંદ કરવા માટે આભાર!

તમારા ઑર્ડરની વિગતો નીચે આપેલી છે:

ઓર્ડર નંબર: {{2}}
ડિલિવરી તારીખ: {{3}}
શર્ટ્સની સંખ્યા: {{4}}
પેન્ટ્સની સંખ્યા: {{5}}

જો તમારા ઑર્ડર બાબતમાં કોઈ પ્રશ્નો હોય તો કૃપા કરીને તમે જલ્દીથી અમારો સંપર્ક કરો. અમે હંમેશા તમારી મદદ કરવા માટે તૈયાર છીએ.`,
  },
];
