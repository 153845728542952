import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // f
  .init({
    debug: process.env.NODE_ENV !== "production",
    lng: localStorage.getItem("i18nextLng"),
    fallbackLng: "gu",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          title: "Darji Point",
          nav: {
            customer: "Customer",
            worker: "Worker",
            debt: "Debt",
            expense: "Expense",
            whatsapp: "Whatsapp",
            logout: "Log out",
          },
          common: {
            login: "Login",
            username: "username",
            password: "password",
            clickMe: "Click me",
            name: "Name",
            address: "Address",
            billNo: "Bill No",
            number: "No",
            date: "Date",
            mobile: "Mobile",
            shirt: "Shirt",
            pant: "Pant",
            save: "Save",
            clear: "Clear",
            find: "Find",
            to: "To",
            total: "Total",
            loading: "Please wait...",
            measurement: "Measurement",
            count: "count",
            details: "Details",
            amount: "₹",
            advance: "Advance",
            delieveryDate: "Delivery Date",
            sendMessage: "Send Message",
            all: "All",
            add: "Add details",
            search: "Search",
            logWork: "Log Work",
            workReport: "Work Report",
            debt: "Debt",
            report: "Report",
            actions: "Actions",
            delete: "Delete",
            update: "Update",
            message: "Message",
            print: "Print",
            download: "Download",
            yes: "Yes",
            no: "No",
            deleteConfirmation: "Are you sure??",
            startDate: "Start date",
            endDate: "End Date",
            confirmDelete: "Are you sure???",
            clothAdvance: "Kapad Na Baki",
            cancle: "Cancle",
            printInvoice: "Print Invoice",
            qty: "Qty.",
            generateBill: "Generate Bill",
            required: "Required",
            noData: "No Data Found",
            welcome: "Welcome to Darji Point",
            viewMeasure: "View Measurement",
            errorMessage:
              "Enter the valid data or Check your internet connection",
            exit: "Logged out successfully",
            whatsappMessage: "Send Whatsapp Message",
            phoneValidation: "Enter Valid Mobile Number",
          },
          error: {
            shirtOrPant: "Select either shirt or pant first",
          },
          pant: {
            type: {
              pant: "Pant",
              halfRubberPant: "Half Rubber Pant",
              jeans: "Jeans",
              cottonJeans: "Cotton Jeans",
              chudidarFartuRubber: "Chudidar Fartu Rubber",
            },
            options: {
              grip: "Grip",
              fittingShape: "Fitting Shape",
              singleSilai: "Single Silai",
              doubleSilai: "Double Silai",
              hathSilai: "Sandha Chirine - Hath Silai",
              moliSilai: "Sandha Chirine - Molima Silai",
              gajButtonChorasBelt: "Gaj Button Choras Belt",
            },
          },
          shirt: {
            type: {
              open: "Open",
              bushart: "Bushirt",
              shortShirt: "Short Shirt",
              openKurto: "Open Kurto",
              kurto: "Kurto",
              jabbho: "Jabbho",
              safari: "Safari",
              sherwani: "Sherwani",
              koti: "Koti",
              suit: "Suit",
              blazzer: "Blazzer",
              indoWestern: "Indo Western",
              hineckKurto: "Hi-neck Kurto",
            },
            options: {
              uparPocket: "Open Pocket",
              andarBaharPocket: "Andar-Bahar Pocket",
              sadaPocket: "Sada(2) Pocket",
              cutPocket: "Cut Pocket",
              uparPatti: "Upar Patti",
              chineseColar: "Chinese Colar",
              colorOneInch: "Collar 1 || inch",
              fancyButton: "Fancy Button",
              ringButton: "Ring Button",
              noPocket: "No Pocket",
              logoTanki: "Logo Tanki",
              pachadDarts: "Pachad Darts",
              pocket2: "Pocket(2) Dhaknawala",
              pocket1: "Pocket(1) Dhaknawala",
              gajButton: "Buy-Patti Gaj Button",
              foldPatti: "Fold Patti",
              halfBuy: "Half Buy",
            },
          },
        },
      },
      gu: {
        translation: {
          title: "દરજી પોઇન્ટ",
          nav: {
            customer: "ગ્રાહક",
            worker: "કારીગર",
            expense: "ખર્ચ",
            whatsapp: "વ્હાટ્સએપ",
            logout: "લોગ આઉટ",
          },
          common: {
            login: "લોગીન કરો",
            username: "નામ",
            password: "પાસવર્ડ",
            clickMe: "અહીંયા ક્લિક કરો",
            laoding: "રાહ જોવો....",
            name: "નામ",
            address: "સરનામું",
            billNo: "બિલ નંબર",
            number: "નંબર",
            date: "તારીખ",
            mobile: "મોબાઈલ",
            shirt: "શર્ટ",
            pant: "પેન્ટ",
            save: "માહિતી સાચવો",
            clear: "માહિતી સાફ કરો",
            find: "શોધો",
            to: "થી",
            total: "ટોટલ",
            loading: "રાહ જોવો...",
            measurement: "માપ",
            count: "નંગ",
            details: "વિગત",
            amount: "₹",
            advance: "જમા ",
            delieveryDate: "ડિલિવરી તારીખ",
            sendMessage: "મેસેજ મોકલો",
            all: "બધા",
            add: "નવી માહિતી",
            logWork: "કામ નાખો",
            workReport: "કામ નો રિપોર્ટ",
            debt: "ઉપાડ",
            report: "રિપોર્ટ",
            search: "શોધ",
            actions: "માહિતી કાઢો/સુધારો",
            delete: "માહિતી કાઢો",
            update: "માહિતી સુધારો",
            message: "મેસેજ",
            print: "પ્રિન્ટ નીકળો",
            download: "ડાઉનલોડ કરો",
            yes: "હા",
            no: "ના",
            deleteConfirmation: "કરો શું તમે માહિતી ડીલીટ કરવા માન્ગો છો?",
            startDate: "શરુ તારીખ",
            endDate: "પુરી તારીખ",
            confirmDelete: "શું તમે ખરેખર માહિતી ડીલીટ કરવા માન્ગો છો?",
            clothAdvance: "કાપડ ના બાકી",
            cancle: "સાફ કરો",
            printInvoice: "બિલ પ્રિન્ટ કરો",
            qty: "નંગ",
            generateBill: "બિલ નીકાલો",
            required: "જરૂરી માહિતી",
            noData: "કોઈ માહિતી મળી નથી",
            welcome: "દરજી પોઇન્ટ માં આપ નું સ્વાગત છે",
            viewMeasure: "માપ જોવો",
            errorMessage: "પૂરતો ડેટા નાખો અથવા તમારું ઈન્ટરનેટ ચકાસો",
            exit: "લોગ આઉટ કરેલ છે",
            whatsappMessage: "વ્હાટ્સએપ મેસેઝ મોકલો",
            phoneValidation: "મોબાઇલ નંબર ચકાસો",
          },
          error: {
            shirtOrPant: "શર્ટ અથવા પંત સિલેક્ટ કરો",
          },
          pant: {
            type: {
              pant: "પેન્ટ",
              halfRubberPant: "હાલ્ફ રબર પેન્ટ",
              jeans: "જીન્સ",
              cottonJeans: "કોટન જીન્સ",
              chudidarFartuRubber: "ચુડીદાર ફરતું રબર",
            },
            options: {
              grip: "ગ્રીપ",
              fittingShape: "ફિટિંગ શેપ",
              singleSilai: "સિંગલ સિલાઈ",
              doubleSilai: "ડબલ સિલાઈ",
              hathSilai: "સાંધા ચીરીને - હાથ સિલાઈ",
              moliSilai: "સાંધા ચીરીને - મોળીમાં સિલાઈ",
              gajButtonChorasBelt: "ગાજ-બટન ચોરસ બેલ્ટ",
            },
          },
          shirt: {
            type: {
              open: "ઓપન",
              bushart: "બુશર્ટ",
              shortShirt: "શોર્ટ શર્ટ",
              openKurto: "ઓપન કુર્તો",
              kurto: "કુર્તો",
              jabbho: "ઝભ્ભો",
              safari: "સફારી",
              sherwani: "શેરવાની",
              koti: "કોટી",
              suit: "શૂટ",
              blazzer: "બ્લેઝર",
              indoWestern: "ઈન્ડો વેસ્ટર્ન",
              hineckKurto: "હાઈનેક કુર્તો",
            },
            options: {
              uparPocket: "ઉપર પોકેટ",
              andarBaharPocket: "અંદર-બહાર પોકેટ",
              sadaPocket: "સાદા(૨) પોકેટ",
              cutPocket: "કટ પોકેટ",
              uparPatti: "ઉપર પટ્ટી",
              chineseColar: "ચાઇનીસ કોલર",
              colorOneInch: "કોલર ૧|| ઇંચ",
              fancyButton: "ફેંસી બટન",
              ringButton: "રિંગ બટન",
              noPocket: "નો પોકેટ",
              logoTanki: "લોગો ટાંકી",
              pachadDarts: "પાછળ ડાર્ટ્સ",
              pocket2: "પોકેટ(૨) ઢાંકણાવાળા",
              pocket1: "પોકેટ(૧) ઢાંકણાવાળા",
              gajButton: "બાઇપટ્ટી ગાજ-બટન",
              foldPatti: "ફોલ્ડ પટ્ટી",
              halfBuy: "હાલ્ફ બાય",
            },
          },
        },
      },
    },
  });

export default i18n;
