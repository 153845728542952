import { useFormik } from "formik";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

import Footer from "../../components/footer/Footer";
import AuthConsumer from "../../utils/auth";
import api from "../../utils/axios";
import * as Yup from "yup";

import "./login.css";
import { message } from "antd";

export const formValidation = Yup.object().shape({
  username: Yup.string().required("common.required"),
  password: Yup.string().required("common.required"),
});

function Login() {
  const { t } = useTranslation();
  const [authed, dispatch] = AuthConsumer();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  // form
  const formik = useFormik({
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      setLoading(true);

      const requestBody = {
        username: values.username,
        password: values.password,
        // lastLoggedInTime: new Date.now(),
      };

      api
        .post("/user/auth", requestBody)
        .then(({ data }) => {
          if (data.responseCode === "OK") {
            message.info(t("common.welcome"));
            dispatch({ type: "login" });
            setTimeout(() => {
              navigate("/", { replace: true });
            }, 500);
          } else {
            alert("invalid username and password");
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          alert("Something went wrong. Check your internet");
        });
    },
  });
  return (
    <div className="container-fluid">
      <div className="row align-self-center">
        <marquee className="marquee-text">
          પેન્ટ, શર્ટ, શૂટ, સફારી તથા શેરવાની ના સ્પેશ્યલીસ્ટ
        </marquee>
        <div className="col-md-8">
          <img
            className="img-fluid"
            src="./images/logo-image1.png"
            alt="logo"
          />
        </div>

        <div className="col-md-4 align-self-center">
          <div className="container-fluid">
            <form onSubmit={formik.handleSubmit}>
              <div className="row login-form gy-4">
                <div className="col-12 text-start">
                  <h1>{t("common.login")}</h1>
                </div>
                <div className="col-12">
                  <label htmlFor="username">{t("common.username")}</label>
                  <input
                    type="text"
                    id="username"
                    name="username"
                    value={formik.values.username}
                    className="form-control"
                    placeholder="Enter username"
                    onChange={formik.handleChange}
                  />
                  {formik.errors.username && formik.touched.username ? (
                    <p className="form-validation">
                      {t(formik.errors.username)}
                    </p>
                  ) : null}
                </div>
                <div className="col-12">
                  <label htmlFor="password">{t("common.password")}</label>
                  <input
                    type="password"
                    id="password"
                    name="password"
                    value={formik.values.password}
                    className="form-control"
                    onChange={formik.handleChange}
                    placeholder="Enter password"
                  />
                  {formik.errors.password && formik.touched.password ? (
                    <p className="form-validation">
                      {t(formik.errors.password)}
                    </p>
                  ) : null}
                </div>
                <div className="col-12">
                  <button type="submit" className="button-primary">
                    {loading ? (
                      <>
                        <span
                          className="spinner-border spinner-border-sm"
                          role="status"
                          aria-hidden="true"
                        ></span>
                        <span className="m-2">{t("common.loading")}</span>
                      </>
                    ) : (
                      <span className="m-2">{t("common.clickMe")}</span>
                    )}
                  </button>
                </div>

                <div className="col"></div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Login;
