import { useFormik } from "formik";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import DatePicker from "react-datepicker";
import api from "../../../utils/axios";
import ReactToPrint from "react-to-print";
import * as Yup from "yup";
import CurrencyFormat from "react-currency-format";
import { message } from "antd";
import moment from "moment";
import { date, handleDatePickerFocus, localDate } from "../../../utils/helper";

const formValidation = Yup.object().shape({
  startDate: Yup.date().required("common.required"),
  endDate: Yup.date().required("common.required"),
});

const Report = () => {
  const componentRef = useRef();
  const { t } = useTranslation();

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      startDate: date,
      endDate: date,
    },
    validationSchema: formValidation,
    onSubmit: (values) => {
      setLoading(true);
      api
        .get(`order/filter/date/${values.startDate}/${values.endDate}`)
        .then(({ data }) => {
          if (data.responseCode === "OK") {
            if (data.body.length === 0) {
              message.info(t("common.noData"));
            }
            setTableData(data.body);
          }
          setLoading(false);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    },
  });

  return (
    <section className="container-fluid">
      <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
        <div className="data-block py-3">
          <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            {/* start date */}
            <div className="col-6">
              <DatePicker
                type="date"
                id="startDate"
                onFocus={handleDatePickerFocus}
                preventOpenOnFocus={true}
                dateFormat="dd/MM/yyyy"
                showYearDropdown
                showMonthDropdown
                placeholderText={t("common.startDate")}
                selected={formik.values.startDate}
                className="form-control"
                onChange={(date) =>
                  formik.setFieldValue("startDate", localDate(date))
                }
              />
              {formik.errors.startDate && formik.touched.startDate ? (
                <p className="form-validation">{t(formik.errors.startDate)}</p>
              ) : null}
            </div>

            {/* end date */}
            <div className="col-6">
              <DatePicker
                type="date"
                id="endDate"
                onFocus={handleDatePickerFocus}
                preventOpenOnFocus={true}
                dateFormat="dd/MM/yyyy"
                minDate={formik.values.startDate}
                placeholderText={t("common.endDate")}
                showYearDropdown
                showMonthDropdown
                selected={formik.values.endDate}
                className="form-control"
                onChange={(date) =>
                  formik.setFieldValue("endDate", localDate(date))
                }
              />
              {formik.errors.endDate && formik.touched.endDate ? (
                <p className="form-validation">{t(formik.errors.endDate)}</p>
              ) : null}
            </div>
          </div>

          <div className="row row-cols-2 row-cols-lg-4 g-2 g-lg-3">
            <div className="col-6">
              <button type="submit" className="button-primary">
                {loading ? (
                  <div className="text-center">
                    <span
                      className="spinner-border spinner-border-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                    <span className="m-2">{t("common.loading")}</span>
                  </div>
                ) : (
                  t("common.find")
                )}
              </button>
            </div>
            {/* print function */}
            <div className="col-6">
              <ReactToPrint
                trigger={() => (
                  <button
                    type="button"
                    className="button-secondary"
                    disabled={!tableData.length}
                  >
                    {t("common.print")}
                  </button>
                )}
                content={() => componentRef.current}
                pageStyle="print"
                documentTitle="Customer Report"
              />
            </div>
          </div>
        </div>
      </form>

      <hr />

      <div className="data-block" ref={componentRef}>
        {loading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="m-2">{t("common.loading")}</span>
          </div>
        ) : (
          tableData &&
          tableData.length !== 0 && (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{t("common.billNo")}</th>
                    <th scope="col">{t("common.date")}</th>
                    <th scope="col">{t("common.name")}</th>
                    <th scope="col">{t("common.mobile")}</th>
                    <th scope="col">{t("common.pant")}</th>
                    <th scope="col">{t("common.shirt")}</th>
                    <th scope="col">{t("common.total")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((order, index) => (
                    <tr key={order.billNo}>
                      <th scope="row">{order.billNo}</th>
                      <td>
                        {order.date && moment(order.date).format("DD/MM/yyyy")}
                      </td>
                      <td>{order.customer.name}</td>
                      <td>{order.customer.mobile}</td>
                      <td>
                        {order.customer.pant && order.customer.pant.pantCount}
                      </td>
                      <td>
                        {order.customer.shirt
                          ? order.customer.shirt.shirtCount
                          : 0}
                      </td>
                      <td>{order.amount ? order.amount : 0}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}
        {tableData.length > 0 && (
          <table className="table">
            <tr>
              <th>{t("common.total")}</th>
              <th>
                {tableData.reduce(
                  (sum, order) =>
                    sum +
                    Number(
                      order.customer.pant ? order.customer.pant.pantCount : 0,
                    ),
                  0,
                )}
              </th>
              <th>
                {tableData.reduce(
                  (sum, order) =>
                    sum +
                    Number(
                      order.customer.shirt
                        ? order.customer.shirt.shirtCount
                        : 0,
                    ),
                  0,
                )}
              </th>
              <th>
                {
                  <CurrencyFormat
                    value={tableData.reduce(
                      (sum, order) =>
                        sum + Number(order.amount ? order.amount : 0),
                      0,
                    )}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"₹"}
                  />
                }
              </th>
            </tr>
          </table>
        )}
      </div>
    </section>
  );
};

export default Report;
