import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { ReactTransliterate, TriggerKeys } from "react-transliterate";
import { selectToggle } from "../../../redux/slices/NavigationSlice";
import { useSelector } from "react-redux";
import { message } from "antd";

import ReactDatePicker from "react-datepicker";
import Actions from "../../../components/Actions";
import api from "../../../utils/axios";
import * as Yup from "yup";
import moment from "moment";
import { date, handleDatePickerFocus, localDate } from "../../../utils/helper";
import Pagination from "../../../components/Pagination";

const formValidation = Yup.object().shape({
  details: Yup.string().required("common.required"),
  amount: Yup.number().required("common.required"),
});

const Add = () => {
  const { t } = useTranslation();
  const lang = useSelector(selectToggle);
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getAllExpense(currentPage, pageSize);
  }, []);

  const getAllExpense = async (page = 0, size = 100) => {
    setTableLoading(true);
    await api
      .get(`expense/limit?page=${page}&size=${size}`)
      .then(({ data }) => {
        setTableData(data.body.content);
        setTotalPages(data.body.totalPages);
        setTableLoading(false);
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAllExpense(pageNumber, pageSize);
  };

  const deleteExpense = (id) => {
    api
      .delete(`expense/${id}`)
      .then(({ data }) => {
        getAllExpense(currentPage, pageSize);
        message.info("expense deleted successfully - " + id);
      })
      .catch((error) => console.error(error));
  };

  const addExpense = (values) => {
    api
      .post("expense", values)
      .then(({ data }) => {
        getAllExpense();
        setLoading(false);
        message.info("expense added successfully");
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleUpdate = (id) => {
    let selectedItem = tableData.find((item) => item.id === id);

    formik.setFieldValue("details", selectedItem.details);
    formik.setFieldValue("amount", selectedItem.amount);
    formik.setFieldValue("id", id);
  };

  const handleDelete = (id) => {
    deleteExpense(id);
  };

  // form
  const formik = useFormik({
    initialValues: {
      date: date,
      details: "",
      amount: "",
    },
    validationSchema: formValidation,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);

      addExpense(values);
      resetForm();
    },
  });

  return (
    <section className="container-fluid">
      <div className="data-block">
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                {/* lane-1 */}
                <div className="d-flex flex-column">
                  {/* date */}
                  <div className="col-md-6">
                    <div>
                      <label>{t("common.date")}</label>
                      <ReactDatePicker
                        onFocus={handleDatePickerFocus}
                        preventOpenOnFocus={true}
                        selected={formik.values.date}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        placeholderText="date"
                        onChange={(date) =>
                          formik.setFieldValue("date", localDate(date))
                        }
                      />
                    </div>
                  </div>
                  {/* details */}
                  <div className="col-md-6 mt-2">
                    <label>{t("common.details")}</label>
                    <ReactTransliterate
                      id="details"
                      renderComponent={(props) => (
                        <textarea cols={5} rows={3} {...props} />
                      )}
                      value={formik.values.details}
                      onChangeText={(text) => {
                        formik.setFieldValue("details", text);
                      }}
                      className="form-control"
                      lang={lang}
                      enabled={lang === "gu"}
                      triggerKeys={[
                        TriggerKeys.KEY_RETURN,
                        TriggerKeys.KEY_ENTER,
                        TriggerKeys.KEY_SPACE,
                        TriggerKeys.KEY_TAB,
                      ]}
                    />
                    {formik.errors.details && formik.touched.details ? (
                      <p className="form-validation">
                        {t(formik.errors.details)}
                      </p>
                    ) : null}
                  </div>
                  {/* amount */}
                  <div className="col-md-6 mt-2">
                    <div>
                      <label>{t("common.amount")}</label>
                      <input
                        value={formik.values.amount}
                        id="amount"
                        type="number"
                        className="form-control"
                        onChange={formik.handleChange}
                      />
                      {formik.errors.amount && formik.touched.amount ? (
                        <p className="form-validation">
                          {t(formik.errors.amount)}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* lane-2*/}
                <div className="row gx-4 mt-3">
                  {/* submit button */}
                  <div className="col-md-3">
                    <button
                      type="submit"
                      className="button-primary"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="m-2">{t("common.loading")}</span>
                        </>
                      ) : (
                        <span className="m-2"> {t("common.save")}</span>
                      )}
                    </button>
                  </div>
                  {/* clear button */}
                  <div className="col-md-3">
                    <button type="reset" className="button-secondary">
                      {t("common.clear")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr />

        {/* table */}
        {tableLoading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="m-2">{t("common.loading")}</span>
          </div>
        ) : (
          tableData &&
          tableData.length !== 0 && (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{t("common.details")}</th>
                    <th scope="col">{t("common.amount")}</th>
                    <th scope="col">{t("common.date")}</th>
                    <th scope="col">{t("common.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((record, index) => (
                    <tr key={index}>
                      <td>{record.details}</td>
                      <td>{record.amount}/-</td>
                      <td>
                        {record.date &&
                          moment(record.date).format("DD/MM/yyyy")}
                      </td>
                      <td>
                        <Actions
                          index={record.id}
                          handleDelete={handleDelete}
                          handleUpdate={handleUpdate}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}
        {totalPages ? (
          <Pagination
            count={totalPages}
            currentPage={currentPage}
            handleChange={handlePageChange}
          />
        ) : null}
      </div>
    </section>
  );
};

export default Add;
