import moment from "moment";

export const date = new Date(
  moment(new Date()).utcOffset("+0530").format("YYYY-MM-DD"),
);

export const localDate = (date) => {
  if (date) {
    return new Date(moment(date).utcOffset("+0530").format("YYYY-MM-DD"));
  }
};

export const handleDatePickerFocus = (event) => {
  const input = event.target;
  input.setSelectionRange(0, 2);
};
