import { Modal } from "antd";
import React from "react";
import { useState } from "react";
import { useTranslation } from "react-i18next";

const Actions = ({ index, handleDelete, handleUpdate }) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [confirmLoading, setConfirmLoading] = useState(false);

  const showModal = () => {
    setOpen(true);
  };

  const handleOk = () => {
    setConfirmLoading(true);
    handleDelete(index);
    setConfirmLoading(false);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <div className="d-flex">
        <button
          className="btn"
          onClick={showModal}
          style={{ cursor: "pointer" }}
        >
          <img
            src="./images/delete.png"
            alt="delete"
            title="delete"
            width={20}
          />
          {t("common.delete")}
        </button>

        <button className="btn" onClick={() => handleUpdate(index)}>
          <img src="./images/edit.png" alt="delete" width={20} title="edit" />{" "}
          {t("common.update")}
        </button>
      </div>

      <Modal
        title="Delete"
        open={open}
        okText={t("common.yes")}
        cancelText={t("common.no")}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
      >
        <p>{t("common.confirmDelete")}</p>
      </Modal>
    </div>
  );
};

export default Actions;
