import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { ReactTransliterate, TriggerKeys } from "react-transliterate";
import Actions from "../../../components/Actions";
import api from "../../../utils/axios";
import { useDispatch, useSelector } from "react-redux";
import { setWorkers } from "../../../redux/slices/WorkerSlice";

import * as Yup from "yup";
import { selectToggle } from "../../../redux/slices/NavigationSlice";
import { message } from "antd";
import moment from "moment";
import { date, handleDatePickerFocus, localDate } from "../../../utils/helper";

const formValidation = Yup.object().shape({
  name: Yup.string().required("common.required"),
  address: Yup.string().required("common.required"),
  mobileNo: Yup.string()
    .min(10, "should be less than or greater than 10 digits")
    .max(10, "should be less than or greater than 10 digits")
    .required("common.required"),
});

const Add = () => {
  const lang = useSelector(selectToggle);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // hooks
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);

  useEffect(() => {
    getAllWorker();
  }, []);

  const getAllWorker = async () => {
    setTableLoading(true);
    api
      .get("worker")
      .then(({ data }) => {
        setTableData(data.body);
        dispatch(setWorkers({ workers: data.body }));
        setTableLoading(false);
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };

  const deleteWorker = async (id) => {
    api
      .delete(`worker/${id}`)
      .then(({ data }) => {
        getAllWorker();
        message.info("Deleted all the data related to worker - " + id);
      })
      .catch((error) => console.error(error));
  };

  const addWorker = async (values) => {
    setLoading(true);

    api
      .post("worker", values)
      .then(({ data }) => {
        getAllWorker();
        message.info("worker added successfully");
        dispatch(setWorkers({ names: tableData }));
      })
      .catch((error) => {
        message.error(t("common.errorMessage"));
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdate = (id) => {
    let selectedItem = tableData.find((item) => item.id === id);

    formik.setFieldValue("name", selectedItem.name);
    formik.setFieldValue("address", selectedItem.address);
    formik.setFieldValue("mobileNo", selectedItem.mobileNo);
    formik.setFieldValue("id", id);
  };

  const handleDelete = (id) => {
    deleteWorker(id);
  };

  // form
  const formik = useFormik({
    initialValues: {
      name: "",
      address: "",
      mobileNo: "",
      date: date,
    },
    validationSchema: formValidation,
    onSubmit: (values, { resetForm }) => {
      addWorker(values);
      resetForm();
    },
  });

  return (
    <section className="container-fluid">
      <div className="data-block">
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                {/* lane-1 */}
                <div className="row gx-4 mt-3">
                  {/* name */}
                  <div className="col-md-6">
                    <label>{t("common.name")}</label>
                    <ReactTransliterate
                      value={formik.values.name}
                      id="name"
                      enabled={lang === "gu"}
                      onChangeText={(text) => {
                        formik.setFieldValue("name", text);
                      }}
                      className="form-control"
                      lang={lang}
                      triggerKeys={[
                        TriggerKeys.KEY_RETURN,
                        TriggerKeys.KEY_ENTER,
                        TriggerKeys.KEY_SPACE,
                        TriggerKeys.KEY_TAB,
                      ]}
                    />
                    {formik.errors.name && formik.touched.name ? (
                      <p className="form-validation">{t(formik.errors.name)}</p>
                    ) : null}
                  </div>
                  {/* address */}
                  <div className="col-md-6">
                    <label>{t("common.address")}</label>
                    <ReactTransliterate
                      value={formik.values.address}
                      id="address"
                      onChangeText={(text) => {
                        formik.setFieldValue("address", text);
                      }}
                      className="form-control"
                      enabled={lang === "gu"}
                      lang={lang}
                      triggerKeys={[
                        TriggerKeys.KEY_RETURN,
                        TriggerKeys.KEY_ENTER,
                        TriggerKeys.KEY_SPACE,
                        TriggerKeys.KEY_TAB,
                      ]}
                    />
                    {formik.errors.address && formik.touched.address ? (
                      <p className="form-validation">
                        {t(formik.errors.address)}
                      </p>
                    ) : null}
                  </div>
                </div>

                {/* lane-2 */}
                <div className="row gx-4 mt-3">
                  {/* mobile */}
                  <div className="col-md-6">
                    <div>
                      <label>{t("common.mobile")}</label>
                      <input
                        value={formik.values.mobileNo}
                        id="mobileNo"
                        type="number"
                        className="form-control"
                        onChange={formik.handleChange}
                      />
                      {formik.errors.mobileNo && formik.touched.mobileNo ? (
                        <p className="form-validation">
                          {t(formik.errors.mobileNo)}
                        </p>
                      ) : null}
                    </div>
                  </div>
                  {/* date */}
                  <div className="col-md-6">
                    <div>
                      <label>{t("common.date")}</label>
                      <ReactDatePicker
                        onFocus={handleDatePickerFocus}
                        preventOpenOnFocus={true}
                        id="date"
                        selected={formik.values.date}
                        className="form-control m-0"
                        dateFormat="dd/MM/yyyy"
                        onChange={(date) =>
                          formik.setFieldValue("date", localDate(date))
                        }
                      />
                    </div>
                  </div>
                </div>

                {/* lane-3 */}
                <div className="row gx-4 mt-3">
                  {/* submit button */}
                  <div className="col-md-6">
                    <button type="submit" className="button-primary">
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="m-2">{t("common.loading")}</span>
                        </>
                      ) : (
                        <span className="m-2"> {t("common.save")}</span>
                      )}
                    </button>
                  </div>
                  {/* clear button */}
                  <div className="col-md-6">
                    <button type="reset" className="button-secondary">
                      {t("common.clear")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <hr />

        {/* table */}
        {tableLoading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="m-2">{t("common.loading")}</span>
          </div>
        ) : (
          tableData &&
          tableData.length !== 0 && (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{t("common.name")}</th>
                    <th scope="col">{t("common.address")}</th>
                    <th scope="col">{t("common.mobile")}</th>
                    <th scope="col">{t("common.date")}</th>
                    <th scope="col">{t("common.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((worker, index) => (
                    <tr key={index}>
                      <td>{worker.name}</td>
                      <td>{worker.address}</td>
                      <td>{worker.mobileNo}</td>
                      <td>
                        {worker.date &&
                          moment(worker.date).format("DD/MM/yyyy")}
                      </td>
                      <td>
                        <Actions
                          index={worker.id}
                          handleDelete={handleDelete}
                          handleUpdate={handleUpdate}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}
      </div>
    </section>
  );
};

export default Add;
