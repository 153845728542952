import React, { useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { Modal } from "antd";
import { pocketType } from "../../data/types";
import { useTranslation } from "react-i18next";

import Moment from "react-moment";
import CurrencyFormat from "react-currency-format";

const PrintBillModal = ({ order, isOpen, setIsOpen }) => {
  const { t } = useTranslation();
  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    documentTitle: order ? `invoice - ${order.billNo}` : "invoice",
    onAfterPrint: () => setIsOpen(false),
  });

  const getPantOptions = (options) => {
    let optionsToString = "";
    if (options) {
      var arr = options.split(",");
      optionsToString = arr.reduce(
        (result, item) => result + t(`pant.options.${item}`) + ",",
        "",
      );
      optionsToString = optionsToString.slice(0, -1);
    }
    return optionsToString;
  };

  const getShirtOptions = (options) => {
    let optionsToString = "";
    if (options) {
      var arr = options.split(",");
      optionsToString = arr.reduce(
        (result, item) => result + t(`shirt.options.${item}`) + ",",
        "",
      );

      optionsToString = optionsToString.slice(0, -1);
    }
    return optionsToString;
  };

  const getPocketType = (type) => {
    var result = pocketType.find((item) => {
      return item.key === type;
    });

    if (result) {
      return result.name;
    } else {
      return type;
    }
  };

  const calculateTotalAmount = (clothAdvance, advance, amount) => {
    let total = 0;

    if (clothAdvance) total += clothAdvance;
    if (advance) total -= advance;
    if (amount) total += amount;

    return total;
  };

  return (
    <>
      <Modal
        title={t("common.printInvoice")}
        centered
        open={isOpen}
        okText={t("common.print")}
        cancelText={t("common.cancle")}
        onOk={handlePrint}
        onCancel={() => setIsOpen(false)}
        maskClosable={false}
        width={1100}
      >
        {order && (
          <div className="row g-4" id="bill" ref={componentRef}>
            {/* worker bill */}
            <div className="col-6 d-flex flex-column">
              {/* pant */}
              {order.customer.pant && (
                <div className="border border-3 h-auto">
                  {/* line-1  */}
                  <div className="row g-1 border-bottom border-1">
                    <div className="col-md-auto">
                      <div
                        className="p-1 fw-semibold"
                        style={{ fontSize: "20px" }}
                      >
                        {t("common.number")} : {order.billNo}
                      </div>
                    </div>
                    <div className="col-auto">
                      <div
                        className="p-1 fw-semibold"
                        style={{ fontSize: "20px" }}
                      >
                        {order.customer.name}
                      </div>
                    </div>
                    <div className="col-auto">
                      <div
                        className="p-1 fw-semibold"
                        style={{ fontSize: "20px" }}
                      >
                        {t("common.date")} :{" "}
                        <Moment subtract={{ days: 1 }} format="DD/MM/YYYY">
                          {order.deliveryDate}
                        </Moment>
                      </div>
                    </div>
                  </div>
                  {/* line-2 */}
                  <div className="row g-1 border-bottom border-1">
                    <div className="col-md-3">
                      <div
                        className="p-1 fw-semibold"
                        style={{ fontSize: "20px" }}
                      >
                        {t("common.pant")} :{" "}
                        {order.customer.pant && order.customer.pant.pantCount
                          ? order.customer.pant.pantCount
                          : 0}
                      </div>
                    </div>
                    <div className="col-auto">
                      <div
                        className="p-1 fw-semibold"
                        style={{ fontSize: "20px" }}
                      >
                        {t(`pant.type.${order.customer.pant.pantType}`)}
                      </div>
                    </div>
                  </div>
                  {/* line-3 */}
                  <div className="col px-3 py-2 text-center">
                    <div className="row g-1">
                      {/* layer-1 */}
                      <div className="row g-1">
                        <div className="col fw-bold fs-3">
                          {order.customer.pant.p1}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.pant.p2}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.pant.p3}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.pant.p4}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.pant.p5}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.pant.p6}
                        </div>
                      </div>
                      {/* layer-2 */}
                      <div className="row g-1">
                        <div className="col fw-bold fs-3">
                          {order.customer.pant.p7}
                        </div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3">
                          {order.customer.pant.p8}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.pant.p9}
                        </div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                      </div>
                      {/* layer-3 */}
                      <div className="row g-1">
                        <div className="col fw-bold fs-3">
                          {order.customer.pant.p10}
                        </div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                      </div>
                    </div>
                  </div>
                  {/* line-4 */}
                  <div className="row row-cols-2">
                    {/* image */}
                    <div className="col-3 text-center">
                      <img
                        src={`./images/pant-images/${order.customer.pant.image}.png`}
                        alt="pant-image"
                        width={100}
                      />
                    </div>
                    {/* additional details */}
                    <div className="col-9">
                      <div className="p-1 fs-5">
                        <strong>
                          {getPocketType(order.customer.pant.pocketType)}
                        </strong>
                      </div>
                      <div className="p-1 fs-5">   
                          {getPantOptions(order.customer.pant.pantOptions)}
                      </div>
                      {order.customer.pant.pantDetails && (
                        <div className="p-1 fs-6">
                          {t("common.details")}:{" "}
                          {order.customer.pant.pantDetails}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {/* shirt */}
              {order.customer.shirt && (
                <div className="border mt-3 border-3 h-auto mt-2">
                  {/* line-1  */}
                  <div className="row g-1 border-bottom border-1">
                    <div className="col-md-auto">
                      <div
                        className="p-1 fw-semibold"
                        style={{ fontSize: "20px" }}
                      >
                        {t("common.number")} : {order.billNo}
                      </div>
                    </div>
                    <div className="col-auto">
                      <div
                        className="p-1 fw-semibold"
                        style={{ fontSize: "20px" }}
                      >
                        {order.customer.name}
                      </div>
                    </div>
                    <div className="col-auto">
                      <div
                        className="p-1 fw-semibold"
                        style={{ fontSize: "20px" }}
                      >
                        {t("common.date")} :{" "}
                        <Moment subtract={{ days: 1 }} format="DD/MM/YYYY">
                          {order.deliveryDate}
                        </Moment>
                      </div>
                    </div>
                  </div>
                  {/* line-2 */}
                  <div className="row g-1 border-bottom border-1">
                    <div className="col-md-3">
                      <div
                        className="p-1 fw-semibold"
                        style={{ fontSize: "20px" }}
                      >
                        {t("common.shirt")} :{" "}
                        {order.customer.shirt && order.customer.shirt.shirtCount
                          ? order.customer.shirt.shirtCount
                          : 0}
                      </div>
                    </div>
                    <div className="col-auto">
                      <div
                        className="p-1 fw-semibold"
                        style={{ fontSize: "20px" }}
                      >
                        {t(`shirt.type.${order.customer.shirt.shirtType}`)}
                      </div>
                    </div>
                  </div>
                  {/* line-3 */}
                  <div className="col px-3 py-2 text-center">
                    <div className="row g-1">
                      {/* layer-1 */}
                      <div className="row g-1">
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s1}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s2}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s3}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s4}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s5}
                        </div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s6}
                        </div>
                      </div>
                      {/* layer-2 */}
                      <div className="row g-1">
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s7}
                        </div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s8}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s9}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s10}
                        </div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s11}
                        </div>
                      </div>
                      {/* layer-3 */}
                      <div className="row g-1">
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s12}
                        </div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                      </div>
                      {/* layer-4 */}
                      <div className="row g-1">
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3">
                          {order.customer.shirt.s13}
                        </div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                        <div className="col fw-bold fs-3"></div>
                      </div>
                    </div>
                  </div>
                  {/* line-4 */}
                  <div className="row row-cols-2">
                    {/* image */}
                    <div className="col-3 text-center">
                      <img
                        src={`./images/shirt-images/${order.customer.shirt.image}.png`}
                        alt="image"
                        width={100}
                      />
                    </div>
                    {/* additional details */}
                    <div className="col-9">
                      <div className="p-1 fs-5">
                        {getShirtOptions(order.customer.shirt.shirtOptions)}
                      </div>
                      {order.customer.shirt.shirtDetails && (
                        <div className="p-1 fs-6">
                          {t("common.details")}:
                          {order.customer.shirt.shirtDetails}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* customer bill */}
            <div className="col-6">
              <div>
                <div className="d-flex">
                  <div className="border border-1 p-1">
                    {/* bill header */}
                    <div className="bill-header mb-2">
                      <img
                        src="./images/bill_header.png"
                        alt="Tailor Shop Logo"
                        width={500}
                      />
                    </div>
                    {/* bill body */}
                    <div className="d-flex flex-column">
                      {/* part-1 */}
                      <div className="d-flex border border-2 rounded-bottom">
                        <div className="col-md-7">
                          <div className="border border-1 border-start-0 border-bottom-0 border-top-0 p-1">
                            {t("common.name")} : {order.customer.name}
                          </div>
                          <div className="border border-1 border-start-0 border-bottom-0 p-1">
                            {t("common.address")} : {order.customer.address}
                          </div>
                          <div className="border border-1 border-start-0 border-bottom-0 p-1">
                            {t("common.mobile")} : {order.customer.mobile}
                          </div>
                        </div>
                        <div className="col-md-5">
                          <div className="border border-1 border-start-0 border-bottom-0 border-top-0 border-end-0 p-1">
                            {t("common.billNo")} : {order.billNo}
                          </div>
                          <div className="border border-1 border-start-0 border-bottom-0 border-end-0 p-1">
                            {t("common.date")} :{" "}
                            <Moment format="DD/MM/YYYY">{order.date}</Moment>
                          </div>
                          <div className="border border-1 border-start-0 border-bottom-0 border-end-0 p-1">
                            {t("common.delieveryDate")} :{" "}
                            <Moment format="DD/MM/YYYY">
                              {order.deliveryDate}
                            </Moment>
                          </div>
                        </div>
                      </div>
                      {/* part-2 */}
                      <div className="d-flex border border-2 rounded-top mt-1">
                        <div className="col-md-8 border border-1 border-start-0 border-bottom-0 border-top-0 p-1 text-center">
                          {t("common.details")}
                        </div>
                        <div className="col-md-1 border border-1 border-start-0 border-top-0 border-bottom-0 p-1 text-center">
                          {t("common.qty")}
                        </div>
                        <div className="col-md-3 p-1 text-center">
                          {t("common.total")}
                        </div>
                      </div>
                      <div className="d-flex border border-2 border-top-0">
                        <div className="col-md-8 border border-1 border-start-0 border-bottom-0 border-top-0 p-1">
                          <div className="my-3 mx-2 text-center">
                            {order.customer.pant &&
                            order.customer.pant.pantCount
                              ? t(`pant.type.${order.customer.pant.pantType}`)
                              : ""}
                          </div>
                          <div className="my-3 mx-2 text-center">
                            {order.customer.shirt &&
                            order.customer.shirt.shirtCount
                              ? t(
                                  `shirt.type.${order.customer.shirt.shirtType}`,
                                )
                              : ""}
                          </div>
                        </div>
                        <div className="col-md-1 border border-1 border-start-0 border-top-0 border-bottom-0 p-1">
                          <div className="my-3 mx-2 text-center">
                            {order.customer.pant &&
                            order.customer.pant.pantCount
                              ? order.customer.pant.pantCount
                              : ""}
                          </div>
                          <div className="my-3 mx-2 text-center">
                            {order.customer.shirt &&
                            order.customer.shirt.shirtCount
                              ? order.customer.shirt.shirtCount
                              : ""}
                          </div>
                        </div>
                        <div className="col-md-3 p-1"></div>
                      </div>
                      {/* part-3 */}
                      <div className="d-flex border border-2 border-top-0">
                        <div className="col-md-9 border border-1 border-start-0 border-bottom-0 border-top-0 p-1">
                          <div className="row">
                            <div className="text-end">
                              {t("common.clothAdvance")}
                            </div>
                            <div className="text-end">
                              {t("common.advance")}
                            </div>
                            <div className="text-end">{t("common.total")}</div>
                          </div>
                        </div>
                        <div className="col-md-3 p-1">
                          <div className="row">
                            <div className="text-center">
                              <CurrencyFormat
                                value={
                                  order.clothAdvance ? order.clothAdvance : 0
                                }
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </div>
                            <div className="text-center">
                              <CurrencyFormat
                                value={order.advance ? order.advance : 0}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </div>
                            <div className="text-center">
                              <CurrencyFormat
                                value={order.amount}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </div>
                            <hr className="m-0" />
                            <div className="text-center">
                              <CurrencyFormat
                                value={calculateTotalAmount(
                                  order.clothAdvance,
                                  order.advance,
                                  order.amount,
                                )}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={"₹"}
                              />
                            </div>
                            {/* blank space */}
                            <div className="text-center mb-4"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <img
                          src="./images/bill_footer.png"
                          alt="Tailor Shop Logo"
                          width={500}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </>
  );
};

export default PrintBillModal;
