import { createContext, useContext, useReducer } from "react";
import { encryptStorage } from "./secure-storage";

/** Store */
const isAuth = encryptStorage.getItem("auth");
const initilaState = { auth: isAuth };

const authContext = createContext(initilaState);

export function reducer(state, action) {
  switch (action.type) {
    case "login":
      encryptStorage.setItem("auth", true);
      return { auth: true };
    case "logout":
      encryptStorage.removeItem("auth");
      return { auth: false };
    default:
      throw new Error();
  }
}

/** Auth Provider */
export function AuthProvider({ children }) {
  const [authed, dispatch] = useReducer(reducer, initilaState);

  return (
    <authContext.Provider value={[authed, dispatch]}>
      {children}
    </authContext.Provider>
  );
}

/** Own Auth Consumer Hook */
export default function AuthConsumer() {
  return useContext(authContext);
}
