import { createBrowserRouter } from "react-router-dom";

// pages
import Customers from "../pages/customer";
import Expense from "../pages/Expense/indedx";
import Login from "../pages/Login";
import ProtectedRoute from "../ProtectedRoute";
import WhatsappMessage from "../pages/message";
import Worker from "../pages/worker";
import NotFound from "../pages/notfound";
import GenerateBill from "../components/Bill";

export const router = createBrowserRouter([
  {
    path: "login",
    element: <Login />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
  {
    path: "",
    element: (
      <ProtectedRoute>
        <Customers />
      </ProtectedRoute>
    ),
  },
  {
    path: "generate",
    element: (
      <ProtectedRoute>
        <GenerateBill />
      </ProtectedRoute>
    ),
  },
  {
    path: "worker",
    element: (
      <ProtectedRoute>
        <Worker />
      </ProtectedRoute>
    ),
  },
  {
    path: "expense",
    element: (
      <ProtectedRoute>
        <Expense />
      </ProtectedRoute>
    ),
  },
  {
    path: "message",
    element: (
      <ProtectedRoute>
        <WhatsappMessage />
      </ProtectedRoute>
    ),
  },
]);
