import React from "react";

const PantMeasurement = ({ formik }) => {
  return (
    <div className="col-md-9">
      <div className="d-flex" style={{ gap: 10 }}>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.pant.p1"
              value={formik.values.customer.pant.p1}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.pant.p7"
              value={formik.values.customer.pant.p7}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.pant.p10"
              value={formik.values.customer.pant.p10}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.pant.p2"
              value={formik.values.customer.pant.p2}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.pant.p3"
              value={formik.values.customer.pant.p3}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.pant.p8"
              value={formik.values.customer.pant.p8}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input type="text" hidden />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.pant.p4"
              value={formik.values.customer.pant.p4}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.pant.p9"
              value={formik.values.customer.pant.p9}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input type="text" hidden />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.pant.p5"
              value={formik.values.customer.pant.p5}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.pant.p6"
              value={formik.values.customer.pant.p6}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PantMeasurement;
