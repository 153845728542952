import React, { useState } from "react";
import Switch from "react-switch";
import AuthConsumer from "../../utils/auth";

import { useTranslation } from "react-i18next";
import { Link, NavLink } from "react-router-dom";
import { links } from "../../data/nav";
import { useDispatch } from "react-redux";
import { setLanguage } from "../../redux/slices/NavigationSlice";

import "./header.css";
import { message } from "antd";

export const Header = ({ index }) => {
  const redux = useDispatch();
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("i18nextLng");

  const [_, dispatch] = AuthConsumer();
  const [toggle, setToggle] = useState(lang === "gu" ? false : true);

  // handlers
  function ActiveLink(props) {
    return (
      <NavLink
        style={({ isActive }) => {
          return {
            color: isActive ? "#f2cc8f" : "#ffffff",
            textDecoration: "none",
            fontSize: 20,
            fontWeight: isActive ? 600 : "",
          };
        }}
        {...props}
      />
    );
  }

  const handleLanguageChange = (nextChecked) => {
    setToggle(nextChecked);
    let lang = "gu";

    if (!toggle) {
      lang = "en";
    }

    redux(setLanguage({ toggle: lang }));
    i18n.changeLanguage(lang);
  };

  return (
    <nav className="navbar navbar-expand-lg navbar-light nav`-light">
      <div className="container-fluid">
        <Link to="/" replace="true">
          <img
            src="./images/tailor.png"
            className="rounded float-start"
            alt="logo"
            width={100}
          ></img>
        </Link>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav me-auto mb-2 mb-lg-0">
            {links.map((link, index) => (
              <ActiveLink key={link.key} to={link.path} className="nav-item">
                {t(link.name)}
              </ActiveLink>
            ))}
          </ul>
          {/* langauge toggle */}
          <div className="d-flex toggle-container">
            <p className="lang-toggle-text">ગુજરાતી</p>
            <Switch
              className="lang-toggle"
              onColor="#f2cc8f"
              onChange={handleLanguageChange}
              checked={toggle}
            />
            <p className="lang-toggle-text">English</p>
          </div>

          {/* logout button */}
          <img
            src="./images/power-off.png"
            alt="logout"
            width={35}
            title="logout"
            className="btn btn-link border-1 border p-1"
            onClick={(e) => {
              message.info(t("common.exit"));
              dispatch({ type: "logout" });
            }}
          />
        </div>
      </div>
    </nav>
  );
};

export default Header;
