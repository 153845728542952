import React from "react";

import { useTranslation } from "react-i18next";
import { ReactTransliterate, TriggerKeys } from "react-transliterate";
import { shirtOptionOne, shirtOptionTwo } from "../data/options";
import { shirtType } from "../data/types";

import ShirtMeasurement from "./measurement/ShirtMeasurement";
import { selectToggle } from "../redux/slices/NavigationSlice";
import { useSelector } from "react-redux";
import ShirtImageDropdown from "./ImageDropDown/ShirtImageDropdown";

const Shirt = ({ formik, options, handleShirtOptionChange }) => {
  const { t } = useTranslation();
  const lang = useSelector(selectToggle);

  return (
    <section className="data-block">
      <div className="row g-2">
        {/* left */}
        <div className="col-md-6">
          <div className="p-3">
            <div className="row g-2">
              <div className="col-md-4">
                {/* dropdown - type */}
                <div className="d-flex flex-column align-items-start ">
                  <select
                    className="form-select"
                    id="customer.shirt.shirtType"
                    onChange={formik.handleChange}
                    value={formik.values.customer.shirt.shirtType}
                  >
                    {shirtType.map((type, index) => (
                      <option key={type.key} value={type.key}>
                        {t(type.name)}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              {/* measurments */}
              <ShirtMeasurement type="shirt" formik={formik} />
            </div>
          </div>
        </div>

        {/* right */}
        <div className="col-md-6">
          <div className="p-3">
            <div className="row g-2">
              <div className="col">
                <ShirtImageDropdown formik={formik} type={"customer.shirt"} />
              </div>
              <div className="col">
                {/* checkbox list - shirt options */}
                <div className="d-flex flex-column">
                  {shirtOptionOne.map(({ key, name }, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={key}
                        value={key}
                        checked={options.includes(key)}
                        onChange={() => handleShirtOptionChange(key)}
                      />
                      <label className="form-check-label" htmlFor={key}>
                        {t(name)}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col">
                {/* checkbox list - shirt options */}
                <div className="d-flex flex-column">
                  {shirtOptionTwo.map(({ key, name }, index) => (
                    <div className="form-check" key={index}>
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={key}
                        value={key}
                        checked={options.includes(key)}
                        onChange={() => handleShirtOptionChange(key)}
                      />
                      <label className="form-check-label" htmlFor={key}>
                        {t(name)}
                      </label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-4">
                {/* count of items */}
                <div className="col m-0">
                  <label>{t("common.count")}</label>
                  <input
                    type={"number"}
                    className="form-control"
                    onFocus={(e) => e.target.select()}
                    id="customer.shirt.shirtCount"
                    onChange={formik.handleChange}
                    value={formik.values.customer.shirt.shirtCount}
                  />
                </div>

                {/* details */}
                <div className="col my-3">
                  <label>{t("common.details")}</label>
                  <ReactTransliterate
                    value={formik.values.customer.shirt.shirtDetails}
                    renderComponent={(props) => (
                      <textarea cols={5} rows={3} {...props} />
                    )}
                    onFocus={(e) => e.target.select()}
                    onChangeText={(text) => {
                      formik.setFieldValue("customer.shirt.shirtDetails", text);
                    }}
                    className="form-control"
                    lang={lang}
                    enabled={lang === "gu"}
                    triggerKeys={[
                      TriggerKeys.KEY_RETURN,
                      TriggerKeys.KEY_ENTER,
                      TriggerKeys.KEY_SPACE,
                      TriggerKeys.KEY_TAB,
                    ]}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </section>
  );
};

export default Shirt;
