import { message } from "antd";
import axios from "axios";
import moment from "moment";

const api = axios.create({
  baseURL: process.env.REACT_APP_BASE_API_URL,
  timeout: 30000,
  withCredentials: false,
  headers: {
    // Authorization: `Basic ${process.env.REACT_APP_AUTH_TOKEN}`,
    // "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/json",
  },
});

api.interceptors.request.use(
  (x) => {
    // logging request
    const request = {
      axiosRequest: {
        date: new Date(moment(new Date()).utcOffset("+0530")),
        method: x.method.toLocaleUpperCase(),
        url: `${x.baseURL}/${x.url}`,
        payload: x.data,
        headers: x.headers,
      },
    };

    process.env.NODE_ENV !== "production" && console.log(request);

    return x;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (x) => {
    // logging reponse
    const response = {
      axiosResponse: {
        date: new Date(moment(new Date()).utcOffset("+0530")),
        status: x.status,
        statusText: x.statusText,
        data: x.data,
      },
    };

    process.env.NODE_ENV !== "production" && console.log(response);

    if (x.status > 500) {
      message.error(
        "Internal Server Error. Please check your internet connection",
      );
    }

    return x;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  },
);

export default api;
