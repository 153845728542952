import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectWorkers } from "../../../redux/slices/WorkerSlice";
import { date, handleDatePickerFocus, localDate } from "../../../utils/helper";

import ReactDatePicker from "react-datepicker";
import ReactToPrint from "react-to-print";
import api from "../../../utils/axios";
import CurrencyFormat from "react-currency-format";
import * as Yup from "yup";

const formValidation = Yup.object().shape({
  startDate: Yup.date().required("common.required"),
  endDate: Yup.date().required("common.required"),
});

const Report = () => {
  const componentRef = useRef();
  const { t } = useTranslation();
  const { workers } = useSelector(selectWorkers);

  // hooks
  const [workerName, setWorkerName] = useState();
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    generateReport();
  }, []);

  // FIX: all worker issue
  const generateReportById = async (id) => {
    try {
      const log = await api.get(`worker/report/log/${id}`);
      const debt = await api.get(`worker/report/debt/${id}`);

      generateFinalReport(log.data.body, debt.data.body);
    } catch (error) {
      console.error(error);
    }
  };

  const generateReportByDate = async (request) => {
    try {
      await api
        .post("worker/report/date", request)
        .then((response) => setTableData(response.data.body));
    } catch (error) {
      console.error(error);
    }
  };

  const generateReportByIdAndDate = async (request) => {
    try {
      await api
        .post("worker/report", request)
        .then((response) => setTableData(response.data.body));
    } catch (error) {
      console.error(error);
    }
  };

  const generateReport = async (id) => {
    try {
      const log = await api.get(`worker/report/log`);
      const debt = await api.get(`worker/report/debt`);

      generateFinalReport(log.data.body, debt.data.body);
    } catch (error) {
      console.error(error);
    }
  };

  const generateFinalReport = (logWork = [], debtWork = []) => {
    let mergeArr = [];
    mergeArr = logWork.map((obj) => {
      const index = debtWork.findIndex((el) => el["id"] === obj["id"]);
      const { amount } = index !== -1 ? debtWork[index] : {};

      return {
        ...obj,
        amount,
      };
    });
    setTableData(mergeArr);
  };

  // handlers
  const handleWorkerChange = async (event) => {
    setLoading(true);
    const id = event.target.value;
    setWorkerName(id);
    if (id !== "all") {
      await generateReportById(id);
    } else {
      await generateReport();
    }
    setLoading(false);
  };

  const formik = useFormik({
    initialValues: {
      startDate: date,
      endDate: date,
    },
    validationSchema: formValidation,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      if (!workerName || workerName === "all") {
        generateReportByDate(values);
      } else {
        values.id = workerName;
        generateReportByIdAndDate(values);
      }
      resetForm();
      setLoading(false);
    },
  });

  return (
    <section className="container-fluid">
      <div className="data-block">
        <form onSubmit={formik.handleSubmit}>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                {/* lane-1 */}
                <div className="row gx-4 mt-3">
                  {/* name */}
                  <div className="col-md-5">
                    <select value={workerName} onChange={handleWorkerChange}>
                      <option value="all">{t("common.all")}</option>
                      {workers &&
                        workers.map((worker, index) => (
                          <option value={worker.id} key={index}>
                            {worker.name}
                          </option>
                        ))}
                    </select>
                  </div>
                </div>

                {/* lane-2 */}
                <div className="row gx-4 mt-3">
                  {/* start date */}
                  <div className="col-md-5">
                    <div>
                      <ReactDatePicker
                        onFocus={handleDatePickerFocus}
                        preventOpenOnFocus={true}
                        id="startDate"
                        selected={formik.values.startDate}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        placeholderText={t("common.startDate")}
                        onChange={(date) =>
                          formik.setFieldValue("startDate", localDate(date))
                        }
                      />
                      {formik.errors.startDate && formik.touched.startDate ? (
                        <p className="form-validation">
                          {t(formik.errors.startDate)}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {/* end date */}
                  <div className="col-md-5">
                    <div>
                      <ReactDatePicker
                        id="endDate"
                        onFocus={handleDatePickerFocus}
                        preventOpenOnFocus={true}
                        minDate={formik.values.startDate}
                        selected={formik.values.endDate}
                        className="form-control"
                        dateFormat="dd/MM/yyyy"
                        showYearDropdown
                        showMonthDropdown
                        placeholderText={t("common.endDate")}
                        onChange={(date) =>
                          formik.setFieldValue("endDate", localDate(date))
                        }
                      />
                      {formik.errors.endDate && formik.touched.endDate ? (
                        <p className="form-validation">
                          {t(formik.errors.endDate)}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* lane-3 */}
                <div className="row gx-4 mt-3">
                  {/* submit button */}
                  <div className="col-md-6">
                    <button
                      type="submit"
                      className="button-primary"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="m-2">{t("common.loading")}</span>
                        </>
                      ) : (
                        <span className="m-2"> {t("common.save")}</span>
                      )}
                    </button>
                  </div>
                  {/* clear button */}
                  <div className="col-md-6">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          type="button"
                          className="button-secondary"
                          disabled={!tableData.length}
                        >
                          {t("common.print")}
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle="print"
                      documentTitle="Worker Report"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr />

        {/* table */}
        {loading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="m-2">{t("common.loading")}</span>
          </div>
        ) : (
          tableData &&
          tableData.length !== 0 && (
            <div className="table-responsive">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    <th scope="col">{t("common.name")}</th>
                    <th scope="col">{t("common.pant")}</th>
                    <th scope="col">{t("common.shirt")}</th>
                    <th scope="col">{t("common.debt")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((record, index) => (
                    <tr key={record.id}>
                      <td>{record.name}</td>
                      <td>{record.pants ? record.pants : 0}</td>
                      <td>{record.shirts ? record.shirts : 0}</td>
                      <td>{record.amount ? record.amount : 0}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}

        {/* total */}
        {tableData.length > 0 && (
          <table className="table text-center">
            <tr>
              <th>{t("common.total")}</th>
              <th>
                {tableData.reduce(
                  (sum, record) => sum + Number(record.pants),
                  0,
                )}
              </th>
              <th>
                {tableData.reduce(
                  (sum, record) => sum + Number(record.shirts),
                  0,
                )}
              </th>
              <th>
                <CurrencyFormat
                  value={tableData.reduce(
                    (sum, record) => sum + Number(record.amount),
                    0,
                  )}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₹"}
                />
              </th>
            </tr>
          </table>
        )}
      </div>
    </section>
  );
};

export default Report;
