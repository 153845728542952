import { Navigate, useLocation } from "react-router-dom";
import AuthConsumer from "./utils/auth";

export const ProtectedRoute = ({ children }) => {
  const [authed] = AuthConsumer();

  const location = useLocation();

  if (!authed.auth) {
    return (
      <Navigate
        to={"/login"}
        replace
        state={{ path: location.pathname }}
      ></Navigate>
    );
  }

  return children;
};

export default ProtectedRoute;
