import { createSlice } from "@reduxjs/toolkit";

export const workerSlice = createSlice({
  name: "worker",
  initialState: {
    workers: [],
  },
  reducers: {
    setWorkers: (state, action) => {
      state.workers = action.payload.workers;
    },
    deleteWorkers: (state, action) => {
      state.workers = [];
    },
  },
});

// Action creators are generated for each case reducer function
export const { setWorkers, deleteWorkers } = workerSlice.actions;

export const selectWorkers = (state) => state.worker;

export default workerSlice.reducer;
