import React from "react";

const ShirtMeasurement = ({ formik }) => {
  return (
    <div className="col-md-9">
      <div className="d-flex" style={{ gap: 10 }}>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              id="customer.shirt.s1"
              value={formik.values.customer.shirt.s1}
              onFocus={(e) => e.target.select()}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s2"
              value={formik.values.customer.shirt.s2}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s7"
              value={formik.values.customer.shirt.s7}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s12"
              value={formik.values.customer.shirt.s12}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s13"
              value={formik.values.customer.shirt.s13}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s3"
              value={formik.values.customer.shirt.s3}
              onChange={formik.handleChange}
            />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s4"
              value={formik.values.customer.shirt.s4}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s8"
              value={formik.values.customer.shirt.s8}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input type="text" hidden />
          </div>
          <div className="col">
            <input type="text" hidden />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s5"
              value={formik.values.customer.shirt.s5}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s9"
              value={formik.values.customer.shirt.s9}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input type="text" hidden />
          </div>
          <div className="col">
            <input type="text" hidden />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input type="text" hidden />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s10"
              value={formik.values.customer.shirt.s10}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input type="text" hidden />
          </div>
          <div className="col">
            <input type="text" hidden />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 10 }}>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s6"
              value={formik.values.customer.shirt.s6}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input
              type="text"
              onFocus={(e) => e.target.select()}
              className="form-control"
              id="customer.shirt.s11"
              value={formik.values.customer.shirt.s11}
              onChange={formik.handleChange}
            />
          </div>
          <div className="col">
            <input type="text" hidden />
          </div>
          <div className="col">
            <input type="text" hidden />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShirtMeasurement;
