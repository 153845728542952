import React, { useRef, useState } from "react";
import ReactDatePicker from "react-datepicker";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";

import * as Yup from "yup";
import api from "../../../utils/axios";
import ReactToPrint from "react-to-print";
import moment from "moment";
import { date, handleDatePickerFocus, localDate } from "../../../utils/helper";
import CurrencyFormat from "react-currency-format";

const formValidation = Yup.object().shape({
  startDate: Yup.date().required("common.required"),
  endDate: Yup.date().required("common.required"),
});

const Report = () => {
  const componentRef = useRef();
  const { t } = useTranslation();

  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(false);

  const getAllExpenseByDate = (values) => {
    setLoading(true);
    api
      .post("/expense/filter/date", values)
      .then(({ data }) => {
        setTableData(data.body);
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const getTotalExpense = () => {
    return tableData.reduce((prev, current) => {
      return prev + current.amount;
    }, 0);
  };

  // form
  const formik = useFormik({
    initialValues: {
      startDate: date,
      endDate: date,
    },
    validationSchema: formValidation,
    onSubmit: (values, { resetForm }) => {
      getAllExpenseByDate(values);
      resetForm();
    },
  });

  return (
    <section className="container-fluid">
      <div className="data-block">
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                {/* lane-1 */}
                <div className="row gx-4 mt-3">
                  {/* start date */}
                  <div className="col-md-6">
                    <ReactDatePicker
                      onFocus={handleDatePickerFocus}
                      preventOpenOnFocus={true}
                      selected={formik.values.startDate}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      showMonthDropdown
                      placeholderText={t("common.startDate")}
                      onChange={(date) =>
                        formik.setFieldValue("startDate", localDate(date))
                      }
                    />
                    {formik.errors.startDate && formik.touched.startDate ? (
                      <p className="form-validation">
                        {t(formik.errors.startDate)}
                      </p>
                    ) : null}
                  </div>
                  {/* end date */}
                  <div className="col-md-6">
                    <ReactDatePicker
                      onFocus={handleDatePickerFocus}
                      preventOpenOnFocus={true}
                      selected={formik.values.endDate}
                      className="form-control"
                      dateFormat="dd/MM/yyyy"
                      showYearDropdown
                      showMonthDropdown
                      minDate={formik.values.startDate}
                      placeholderText={t("common.endDate")}
                      onChange={(date) =>
                        formik.setFieldValue("endDate", localDate(date))
                      }
                    />
                    {formik.errors.endDate && formik.touched.endDate ? (
                      <p className="form-validation">
                        {t(formik.errors.endDate)}
                      </p>
                    ) : null}
                  </div>
                </div>

                {/* lane-2 */}
                <div className="row gx-4 mt-3">
                  {/* submit button */}
                  <div className="col-md-4">
                    <button
                      type="submit"
                      className="button-primary"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="m-2">{t("common.loading")}</span>
                        </>
                      ) : (
                        <span className="m-2"> {t("common.save")}</span>
                      )}
                    </button>
                  </div>
                  {/* download button */}
                  <div className="col-md-4">
                    <button type="reset" className="button-secondary">
                      {t("common.clear")}
                    </button>
                  </div>

                  {/* print button */}
                  <div className="col-md-4">
                    <ReactToPrint
                      trigger={() => (
                        <button
                          className="button-secondary"
                          type="button"
                          disabled={!tableData.length}
                        >
                          {t("common.print")}
                        </button>
                      )}
                      content={() => componentRef.current}
                      pageStyle="print"
                      documentTitle="Expense Report"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
        <hr />

        {/* table */}
        {loading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="m-2">{t("common.loading")}</span>
          </div>
        ) : (
          tableData &&
          tableData.length !== 0 && (
            <div className="table-responsive">
              <table className="table" ref={componentRef}>
                <thead>
                  <tr>
                    <th scope="col">{t("common.details")}</th>
                    <th scope="col">{t("common.date")}</th>
                    <th scope="col">{t("common.amount")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((expense, index) => (
                    <tr key={expense.id}>
                      <td>{expense.details}</td>
                      <td>
                        {expense.date &&
                          moment(expense.date).format("DD/MM/yyyy")}
                      </td>
                      <td>{expense.amount}/-</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}

        {tableData.length > 0 && (
          <table className="table">
            <tr>
              <th> {t("common.total")}</th>
              <th>
                <CurrencyFormat
                  value={getTotalExpense()}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"₹"}
                />
              </th>
            </tr>
          </table>
        )}
      </div>
    </section>
  );
};

export default Report;
