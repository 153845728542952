import React, { useEffect, useState } from "react";
import ReactDatePicker from "react-datepicker";

import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { selectWorkers } from "../../../redux/slices/WorkerSlice";
import { message } from "antd";

import Actions from "../../../components/Actions";
import api from "../../../utils/axios";
import * as Yup from "yup";
import moment from "moment";
import { date, handleDatePickerFocus, localDate } from "../../../utils/helper";
import Pagination from "../../../components/Pagination";

const formValidation = Yup.object().shape({
  shirt: Yup.number().required("common.required"),
  pant: Yup.number().required("common.required"),
});

const LogWork = () => {
  const { t } = useTranslation();
  const { workers } = useSelector(selectWorkers);

  // hooks
  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);
  const [pageSize, setPageSize] = useState(100);
  const [currentPage, setCurrentPage] = useState(0);
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    getAllWorkerLogs(currentPage, pageSize);
  }, []);

  const getAllWorkerLogs = async (page = 0, size = 100) => {
    setTableLoading(true);
    api
      .get(`workerLog/limit?page=${page}&size=${size}`)
      .then(({ data }) => {
        setTableData(data.body.content);
        setTotalPages(data.body.totalPages);
        setTableLoading(false);
      })
      .catch((error) => {
        setTableLoading(false);
        console.error(error);
      });
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    getAllWorkerLogs(pageNumber, pageSize);
  };

  const deleteWorkerLog = async (id) => {
    api
      .delete(`workerLog/${id}`)
      .then(({ data }) => {
        getAllWorkerLogs(currentPage, pageSize);
        message.info("log deleted - " + id);
      })
      .catch((error) => console.error(error));
  };

  const addWorkerLog = async (values) => {
    setLoading(true);
    api
      .post("workerLog", values)
      .then(({ data }) => {
        getAllWorkerLogs();
        message.info("log added successfully");
        setLoading(false);
      })
      .catch((error) => {
        message.error(t("common.errorMessage"));
        setLoading(false);
        console.error(error);
      });
  };

  const handleUpdate = (id) => {
    let selectedItem = tableData.find((item) => item.id === id);

    formik.setFieldValue("workerId", selectedItem.workerId);
    formik.setFieldValue("pant", selectedItem.pant);
    formik.setFieldValue("shirt", selectedItem.shirt);
    formik.setFieldValue("id", id);
  };

  const handleDelete = (id) => {
    deleteWorkerLog(id);
  };

  // form
  const formik = useFormik({
    initialValues: {
      workerId: "",
      date: date,
      shirt: "",
      pant: "",
    },
    validationSchema: formValidation,
    onSubmit: (values, { resetForm }) => {
      setLoading(true);
      addWorkerLog(values);
      resetForm();
    },
  });

  return (
    <section className="container-fluid">
      <div className="data-block">
        <form onSubmit={formik.handleSubmit} onReset={formik.handleReset}>
          <div className="row">
            <div className="col-md-6">
              <div className="row">
                {/* lane-1 */}
                <div className="row gx-4 mt-3">
                  {/* name */}
                  <div className="col-md-6">
                    <label>{t("common.name")}</label>
                    <select
                      id="workerId"
                      disabled={workers && !workers.length}
                      placeholder="Select Worker"
                      title="Select Worker"
                      value={formik.values.workerId}
                      onChange={formik.handleChange}
                    >
                      <option value="" key="">
                        Select Worker
                      </option>
                      {workers &&
                        workers.map((worker, index) => (
                          <option value={worker.id} key={index}>
                            {worker.name}
                          </option>
                        ))}
                    </select>
                  </div>
                  {/* date */}
                  <div className="col-md-6">
                    <label>{t("common.date")}</label>
                    <ReactDatePicker
                      onFocus={handleDatePickerFocus}
                      preventOpenOnFocus={true}
                      id="date"
                      selected={formik.values.date}
                      className="form-control m-0"
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) =>
                        formik.setFieldValue("date", localDate(date))
                      }
                    />
                  </div>
                </div>

                {/* lane-2 */}
                <div className="row gx-4 mt-3">
                  {/* pant */}
                  <div className="col-md-6">
                    <div>
                      <label>{t("common.pant")}</label>
                      <input
                        type="number"
                        className="form-control"
                        id="pant"
                        value={formik.values.pant}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.pant && formik.touched.pant ? (
                        <p className="form-validation">
                          {t(formik.errors.pant)}
                        </p>
                      ) : null}
                    </div>
                  </div>

                  {/* shirt */}
                  <div className="col-md-6">
                    <div>
                      <label>{t("common.shirt")}</label>
                      <input
                        type="number"
                        className="form-control"
                        id="shirt"
                        value={formik.values.shirt}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.shirt && formik.touched.shirt ? (
                        <p className="form-validation">
                          {t(formik.errors.shirt)}
                        </p>
                      ) : null}
                    </div>
                  </div>
                </div>

                {/* lane-3 */}
                <div className="row gx-4 mt-3">
                  {/* submit button */}
                  <div className="col-md-6">
                    <button
                      type="submit"
                      className="button-primary"
                      disabled={loading}
                    >
                      {loading ? (
                        <>
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          ></span>
                          <span className="m-2">{t("common.loading")}</span>
                        </>
                      ) : (
                        <span className="m-2"> {t("common.save")}</span>
                      )}
                    </button>
                  </div>
                  {/* clear button */}
                  <div className="col-md-6">
                    <button type="reset" className="button-secondary">
                      {t("common.clear")}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>

        <hr />

        {/* table */}
        {tableLoading ? (
          <div className="text-center">
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            ></span>
            <span className="m-2">{t("common.loading")}</span>
          </div>
        ) : (
          tableData &&
          tableData.length !== 0 && (
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">{t("common.name")}</th>
                    <th scope="col">{t("common.pant")}</th>
                    <th scope="col">{t("common.shirt")}</th>
                    <th scope="col">{t("common.date")}</th>
                    <th scope="col">{t("common.actions")}</th>
                  </tr>
                </thead>
                <tbody>
                  {tableData.map((workerLog, index) => (
                    <tr key={workerLog.id}>
                      <td>{workerLog.name}</td>
                      <td>{workerLog.pant}</td>
                      <td>{workerLog.shirt}</td>
                      <td>
                        {workerLog.date &&
                          moment(workerLog.date).format("DD/MM/yyyy")}
                      </td>
                      <td>
                        <Actions
                          index={workerLog.id}
                          handleDelete={handleDelete}
                          handleUpdate={handleUpdate}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )
        )}

        {/* pagiantion */}
        {totalPages ? (
          <Pagination
            count={totalPages}
            currentPage={currentPage}
            handleChange={handlePageChange}
          />
        ) : null}
      </div>
    </section>
  );
};

export default LogWork;
