import React, { useEffect, useState } from "react";
import { pantImageOptions } from "../../data/image-options";

const PantImageDropdown = ({ formik }) => {
  const [options] = useState(pantImageOptions);
  const [selectedOption, setSelectedOption] = useState(
    formik.values.customer.pant.image,
  );
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setSelectedOption(formik.values.customer.pant.image);
  }, [formik.values.customer.pant.image]);

  return (
    <div className="dropdown">
      <button
        className="dropdown-toggle image-dropdown"
        onClick={(e) => {
          e.preventDefault();
          setIsOpen(!isOpen);
        }}
      >
        <img
          src={`./images/pant-images/${selectedOption}.png`}
          alt={selectedOption}
          width={75}
        />
      </button>
      <div
        className={`dropdown-menu ${isOpen ? "show" : ""}`}
        style={{
          height: "auto",
          maxHeight: 400,
          overflowY: "scroll",
          width: 50,
          padding: 0,
        }}
      >
        {options.map((option) => (
          <button
            key={option.key}
            className="dropdown-item"
            onClick={(e) => {
              e.preventDefault();
              formik.setFieldValue("customer.pant.image", option.key);
              setSelectedOption(option.key);
              setIsOpen(false);
            }}
          >
            <img
              src={`./images/pant-images/${option.key}.png`}
              alt={option.key}
              width={75}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
              }}
            />
          </button>
        ))}
      </div>
    </div>
  );
};

export default PantImageDropdown;
