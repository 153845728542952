import { createSlice } from "@reduxjs/toolkit";

export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    currentTab: "add",
    order: {},
    loading: false,
  },
  reducers: {
    changeTab: (state, action) => {
      state.currentTab = action.payload.key;
    },
    setCustomer: (state, action) => {
      state.order = action.payload.order;
    },
    removeCustomer: (state, action) => {
      state.order = {};
    },
  },
});

export const { changeTab, setCustomer, removeCustomer } = customerSlice.actions;

export const selectCustomer = (state) => state.customer;

export default customerSlice.reducer;
